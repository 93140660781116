import { createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/img/iconfont.css";

import 'lib-flexible'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import VueClipboard from "vue-clipboard2";


import { Message } from 'element-plus';

createApp(App) 
  .use(router)
  .use(store)
  .use(VueClipboard)
  .use(ElementPlus, { locale })
  .use(Message)
  .mount("#app")
  .use(preview);

