import { createStore } from "vuex";

export default createStore({
  state: {
    emic: "",
    emiccalledid: "",
    emiccallid: "",
    ismain: "",
    istechcommission: "",
    stype:1,
    isFin:"",
    lookonly:'',
    emicmode:'',
  },
  mutations: {
    getshow(state, val) {
      state.istechcommission = val;
    },
    getmain(state, val) {
      state.ismain = val;
    },
    getfin(state, val) {
      state.isFin = val;
    },
    getlook(state, val) {
      state.lookonly = val;
    },
    getlook(state, val) {
      state.lookonly = val;
    },
    getmode(state, val) {
      state.emicmode = val;
    },
  },
  actions: {},
  modules: {},
});
