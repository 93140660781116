<template>
  <div>
    <!-- 完成后修改 -->
    <div class="box">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main" v-if="detail">
        <div class="main-nav">
          <h1>公里数修改</h1>
          <img
            class="shua"
            src="../assets/img/ding/shua.png"
            alt=""
            @click="refresh(detail.订单号)"
          />
          <i class="xin" @click="refresh(detail.订单号)">刷新</i>
        </div>
        <!-- 订单号 -->
        <div v-if="hao">
          <span class="tiao"></span>
          <div class="order">
            订单号:{{ detail.订单号 }}
            <span class="take">{{ detail.服务状态 }}</span>
            <span class="take1" v-if="detail.rescue_style == 1">即时单</span>
            <span class="take2" v-if="detail.rescue_style == 2">预约单</span>
            <span class="take3" v-if="detail.rescue_style == 3">顺路单</span>
          </div>
          <div class="order-main">
            <div class="hezuo">
              <div class="p1">
                <span class="title">合作单位&#12288;</span>
                <span class="title-main">{{ detail.合作单位 }}</span>
              </div>
              <div class="xiangmu">
                <span class="title">服务项目</span>
                <i class="title-main yanse">{{ detail.服务项目 }}</i>
              </div>
            </div>
            <div class="hezuo">
              <div class="p1">
                <span class="title">创建日期&#12288;</span>
                <span class="title-main">{{ detail.创建时间 }}</span>
              </div>
              <div class="xiangmu" v-if="detail.rescue_time">
                <span class="title">救援时间</span>
                <i class="title-main yanse">{{ detail.rescue_time }}</i>
              </div>
            </div>

            <div class="diBox" v-if="detail.arrive">
              <label class="remTit">实际任务点</label>

              <div class="diTxt">{{ detail.arrive }}</div>
            </div>

            <div class="diBox" v-if="detail.arrive">
              <span class="remTit">实际目的地</span>

              <div class="diTxt">{{ detail.stop }}</div>
            </div>

            <div class="diBox" v-if="detail.close">
              <span class="remTit">取消位置&#12288;</span>

              <div class="diTxt">{{ detail.close }}</div>
            </div>

            <div class="diBox">
              <label class="remTit">任务点&#12288;&#12288;</label>

              <div class="diTxt">{{ detail.事故地点 }}</div>
            </div>

            <div class="diBox" v-if="detail.目的地">
              <span class="remTit">目的地&#12288;&#12288;</span>

              <div class="diTxt">{{ detail.目的地 }}</div>
            </div>

            <div class="diBox">
              <span class="remTit">接单人员&#12288;</span>
              <span class="diTxt1"
                >{{ detail.接单公司 }}{{ detail.接单人员
                }}<button
                  @click="call(detail.takemobile, detail.订单号)"
                  class="call"
                  v-show="getEmic != '' && detail.takemobile != ''"
                ></button
              ></span>
            </div>
            <div class="diBox" v-if="detail.closereason">
              <span class="remTit">关闭原因&#12288;</span>
              <span class="diTxt1">{{ detail.closereason }}</span>
            </div>

            <div class="remBox" v-if="detail.订单备注">
              <label class="remTit">订单备注&#12288;</label>
              <div class="remTxt" v-html="detail.订单备注"></div>
            </div>
          </div>
        </div>
        <!-- 车主信息 -->
        <div v-if="hao">
          <div class="owner">
            <span class="tiao1"></span>
            车主信息
          </div>
          <div class="owner-main">
            <div class="hezuo">
              <div class="p2">
                <label class="title">车主信息&#12288;</label>
                <span class="title-main">{{ detail.车主姓名 }}</span>
              </div>
              <div class="xiangmu">
                <label class="title">车牌号&#12288;</label>
                <span class="title-main2">{{ detail.车牌号 }}</span>
              </div>
            </div>

            <div class="hezuo">
              <div class="p2">
                <span class="title">手机号&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.手机号
                  }}<button
                    @click="call(detail.手机号, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </div>

              <p class="xiangmu">
                <span class="title" v-if="detail.编号"
                  >编号&#12288;&#12288;</span
                >
                <span class="title-main2" v-if="detail.编号">{{
                  detail.编号
                }}</span>
              </p>
            </div>
            <div class="hezuo" v-if="detail.givename">
              <p class="p2">
                <span class="title">下单人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.givename }}/{{ detail.givemobile
                  }}<button
                    @click="call(detail.givemobile, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
            <div class="hezuo" v-if="detail.name1">
              <p class="p2">
                <span class="title">发车人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.name1 }}/{{ detail.tel1
                  }}<button
                    @click="call(detail.tel1, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
            <div class="hezuo" v-if="detail.name2">
              <p class="p2">
                <span class="title">接车人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.name2 }}/{{ detail.tel2
                  }}<button
                    @click="call(detail.tel2, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>

            <div class="hezuo" v-if="detail.saleman">
              <p class="p2">
                <span class="title">业务员&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.saleman
                  }}<button
                    @click="call(detail.salemobile, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
          </div>
        </div>
   
        <!-- 可修改内容 -->
        <div>
          <div class="owner">
            <span class="tiao1"></span>
            可修改内容
          </div>

          <div class="feiBox">
            <div class="nor1">
              <span class="norm">收费标准</span>
              <span class="normMain">{{ detail.收费标准 }}</span>
              <span class="tuo" v-if="detail.免托">{{ detail.免托 }}</span>
            </div>
            <div class="nor1" v-if="detail.流转标准">
              <span class="norm">流转标准</span>
              <span class="normMain">{{ detail.流转标准 }}</span>
            </div>
            <div class="nor1">
              <span class="norm">行驶距离</span>
              <span class="normMain">{{ julitit }}公里/预估{{plantit}}公里</span>
              <span class="tuo">修改</span>
              <input
                class="xiuinput"
                type="text"
                v-model="travel"
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入修改的行驶距离"
              />
            </div>
            <div class="nor1">
              <span class="norm">订单总价</span>
              <span class="normMain">{{ pricrtit }}元</span>
            </div>
            <div class="nor1" v-if="detail.is_expatriate == 1">
              <span class="norm">流转总价</span>
              <span class="normMain">{{ pricrtit2 }}元</span>

            </div>
            <div class="nor1" v-if="detail.editopprice !== ''">
              <span class="norm">外协价格</span>
              <span class="normMain">{{ pricrtit3 }}元</span>
             
            </div>
          </div>
        </div>



        <div class="btn">
          <button class="set" @click="savexiu">保存修改</button>
          <button class="cancel" @click="beizhu(detail.订单号)">添加备注</button>
        </div>
      </div>
    </div>
  </div>
  <remarks :isBei="isbeis" :remarkid="remarksid" @remarksx="sx"></remarks>
</template>

<script>
import Check from "./check.vue";
import rescueEdit from "../components/rescueEdit.vue";
import dingGai from "../components/dingGai.vue";
import remarks from "../components/remarks.vue";
import * as api1 from "../api/orderDetail";
import * as api from "../api/index";
import * as ola from "../api/olawebsock.js";
import { ElMessageBox } from "element-plus";
//import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from "@vue/reactivity";
import AMap from "AMap";

export default {
  props: ["idK"],
  components: { Check, rescueEdit, remarks, dingGai },
  data() {
    return {
      yinK: false,
      audio: 0,
      detail: {},
      log: [],
      zhipaiId: "",
      photo1: [],
      photo2: [],
      photo3: [],
      photoempty: [],
      photoplus: [],
      photoall: [],
      map: null,
      center: [121.483874, 29.861542],
      showedit: 0,
      rescueId: this.idK,
      srcList: [],
      remarksid: "",
      isbeis: 0,
      zhi: 0,
      hao: true,
      emicreg: "",
      emiclog: [],

      xiuhezuo: "",
      hzlist:[],
      travel: "",
      xiuprice: "",
      xiucarnum: "",
      platprice:'',
      opprice:'',
      julitit: "500",
      pricrtit: "500",
      pricrtit2:"",
      plantit:'',
      danwei: "测试测试测试",
      carnum: "浙B888",
      pricrtit3:"",
      opprice:''
    };
  },
  created() {
    this.refresh(this.idK);
  },

  methods: {
    close() {
      this.$emit("closepartKm");
      this.hao = true;
    },
    filfull() {
      this.audio++;
      if (this.audio == 3) {
        this.audio = 1;
      }
    },
    fangda() {
      this.hao = false;

    },
    huan() {
      this.hao = true;

    },
    xiuchange(data) {
        console.log('选中：',data);
    },
    async savexiu() {
        if(this.travel != '')
        {
          //this.$message.warning('同时修改距离及总价，以总价为最终修改结果');
            ElMessageBox.confirm("确认修改", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
               let res = await api.changeKm(this.rescueId,this.travel)
              if(res.code == 0)
              {
                this.$message.warning(res.message);
              }else{
                this.$message.success('成功');
                this.close();
              }
            })
            .catch(() => {
              this.$message.info("取消修改");
            });
        }else{
          this.$message.warning('请填写要更改的公里数');
        }

    },

    sx() {
      this.refresh(this.rescueId);
      this.remarksid = "";
    },
    sx1() {
      this.refresh(this.rescueId);
      this.rescueIdx = "";
    },
    sx2() {
      this.refresh(this.rescueId);
    },
    mouseenterFun() {
      this.yinK = true;
    },
    mouseleaveFun() {
      this.yinK = false;
    },
    async refresh(id) {
      let res_orderDetail = await api1.OrderDetail(id);
      this.detail = res_orderDetail.data;
      this.danwei = this.detail.合作单位;
      this.carnum = this.detail.车牌号;
      this.julitit = this.detail.distance;
      this.plantit = this.detail.plandistance;
      this.pricrtit = this.detail.src_price;
      this.pricrtit2 = this.detail.to_price;
      this.pricrtit3 = this.detail.editopprice


    },
    zhipai(id) {
      console.log("点击了-------------");
      this.zhi++;
      if (this.zhi == 3) {
        this.zhi = 1;
      }
      this.rescueId = id;
    },

    edit(id) {
      this.showedit++;
      if (this.showedit == 3) {
        this.showedit = 1;
      }
      this.rescueIdx = id;
    },
    beizhu(id) {
      this.isbeis++;
      if (this.isbeis == 3) {
        this.isbeis = 1;
      }
      this.remarksid = id;
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    clickimg(e) {},
    // 详情指派按钮
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    idK(newval) {
      console.log(idK);
      this.rescueId = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.norm,
.roam,
.tuo1,
.tuo2 {
  width: 100px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background-color: #f8fafc;
  color: #2a3346;
  font-size: 16px;
  // border: 1px solid #DADFE6;
  border-left: 1px solid #dadfe6;
  border-right: 1px solid #dadfe6;
  box-sizing: border-box;
}
.norm {
  border-right: 1px solid #dadfe6;
}
.roam {
  border-top: 0;
}
.nor1,
.nor2,
.nor3,
.nor4 {
  width: 637px;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.nor1 {
  display: flex;
}
.nor1 /deep/ .el-select .el-input__inner {
  width: 250px;
  height: 50px;
  box-sizing: border-box;
  margin-left: 20px;
  box-shadow: none;
}
.nor2 {
  border-top: 0;
}
.nor3 {
  border-top: 0;
}
.nor4 {
  border-top: 0;
}
.normMain,
.tuo,
.roamMain,
.tuo1Main,
.yuMain,
.tuo2Main {
  height: 50px;
  line-height: 50px;
  vertical-align: top;
  padding-left: 10px;
  display: inline-block;
  // padding-right: 15px;
  display: inline-block;
  font-size: 14px;
  // border: 1px solid #DADFE6;
  box-sizing: border-box;
  border-left: 0;
}
.tuo {
  color: #2c68ff;
}
.normMain {
  width: 220px;
  //  border-left: 1px solid #DADFE6;
  //  border-right: 1px solid #dadfe6;
}

.tuo1Main {
  width: 165px;
  border-right: 1px solid #dadfe6;
}
.roamMain,
.tuo2Main {
  width: 180px;
  // border-left: 1px solid #dadfe6;
}
.stateTitle {
  color: #fa8c16;
  font-size: 14px;
  vertical-align: middle;
  margin-right: 4px;
}
.stateImg {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}
.state {
  display: inline-block;
  cursor: pointer;
}
.yinState {
  width: 220px;
  height: 110px;
  background-color: #fff;
  border-radius: 2px;
  margin-left: 82px;
  margin-top: 7px;
  // position: relative;
  position: absolute;
  // top: 45px;
  left: 50px;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  z-index: 99;
  padding: 0px;
  background-image: url("../assets/img/icon/dian.png");
  background-size: 100% 100%;
}
.yinState::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 88px;
  top: -14px;
  border: 7px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.yinUl {
  list-style: none;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.yinUl li {
  margin-left: 5px;
  float: left;
  margin-top: 5px;
}
.yinImg {
  width: 16px;
  height: 21px;
  vertical-align: middle;
  margin-right: 4px;
}
.yinTitle {
  font-size: 10px;
  vertical-align: middle;
}

// 相关费用
.feiBox {
  margin-left: 30px;
  // margin-top: 20px;
}
.feiTop {
  width: 636px;
  height: 50px;
  // border-bottom: 1px solid #DADFE6;
}
.feiBottom {
  height: 50px;
}

.toll {
  float: left;
  width: 100px;
  height: 50px;
  color: #2a3346;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  background-color: #f8fafc;
  border: 1px solid #dadfe6;
  box-sizing: border-box;
}
.milage {
  float: left;
  width: 200px;
  height: 50px;
  font-size: 14px;
  border: 1px solid #dadfe6;
  border-left: 0;
  padding-left: 15px;
  line-height: 50px;
  box-sizing: border-box;
}
.avoid {
  float: left;
  width: 115px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  border-left: 0;
  box-sizing: border-box;
}
.milage1 {
  width: 100px;
  line-height: 50px;
  height: 50px;
  float: left;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dadfe6;
  box-sizing: border-box;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 637px;
  height: 500px;
}

.duration {
  float: left;
  margin-top: 12px;
  width: 280px;
  // height: 98px;
  padding-left: 25px;

  background: #f8fafc;
  opacity: 1;
  border-radius: 2px;
  // position: absolute;
  // top: 185px;
  // right: 60px;
}
.duration-p1 {
  width: 100%;
  padding: 5px 0;
  float: left;
}
.duration-p {
  width: 50%;
  padding: 5px 0;
  float: left;
}
.duration-img,
.duration-img1 {
  width: 14px;
  height: 14px;
  line-height: 16px;
  margin-right: 10px;
  opacity: 1;
}
.duration-img {
  border-radius: 50%;
}
.duration-span {
  margin-right: 10px;
}

.box {
  width: 750px;
  // height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;

  z-index: 33;
}

.cha {
  float: left;
  width: 103px;
  height: 103px;
  position: absolute;
  left: -31px;
  top: 36px;
}
.box-main {
  width: 720px;
  height: 920px;
  //   background: orange;
  background-color: #fff;
  margin-left: 40px;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  cursor: pointer;
  padding-left: 20px;
}
h1 {
  padding-top: 20px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
i {
  font-style: normal;
}
.xin {
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 58px;
  right: 40px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50px;
  right: 80px;
}
.order {
  // width: 380px;
  height: 21px;
  font-size: 16px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}

.take,
.take1,
.take2,
.take3 {
  display: inline-block;
  // width: 70px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}
.take1 {
  color: #2c68ff;
  background: #e8f3fe;
}
.take2 {
  color: #27cc8b;
  background: #eefbf4;
}
.take3 {
  color: #fff6e9;
  background: #ff9100;
}
.order-main {
  //width: 510px;
  height: auto;
  opacity: 1;
  background-color: #fff;
}
.hezuo {
  display: flex;
}
.p1 {
  margin-left: 30px;
  // padding-left: 7px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
}
.jiedan {
  width: 500px;
}
.xiangmu {
  // margin-left: 20px;
  display: flex;
}
.yanse {
  color: #2c68ff;
}

.remBox,
.diBox {
  display: flex;
  width: 730px;
  // flex-direction: column;
  margin-left: 30px;
  font-size: 14px;
  box-sizing: border-box;
}

.diBox {
  margin-bottom: 10px;
}
.remTit {
  margin-right: 30px;
}

.diTxt {
  color: #2c68ff;
}
.remTxt {
  width: 500px;
}
.diTxt,
.diTxt1 {
  width: 500px;
}

.p2 {
  margin-left: 30px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
}
.p3 {
  margin-left: 30px;
}
.title {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 30px;
}

.title-i {
  font-size: 14px;
  color: #2c68ff;
  margin-left: 10px;
}
.title1 {
  color: #2c68ff;
}
.owner-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-main,
.title-main2 {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.title-main2 {
  width: 126px;
}
.tit-b {
  margin-left: 60px;
}

// .tit,
// .tit1 {
//   width: 56px;
//   height: 19px;
//   font-size: 14px;
//   font-family: Microsoft YaHei;
//   font-weight: 400;
//   line-height: 0px;

//   color: #2a3346;
//   opacity: 1;
// }
// .tit-main {
//   width: 70px;
//   height: 19px;
//   font-size: 14px;
//   font-family: Microsoft YaHei;
//   font-weight: 400;
//   line-height: 0px;
//   margin-left: 30px;
//   color: #2c68ff;
//   opacity: 1;
// }
.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
.peoper,
.owner1 {
  width: 500px;
  height: 21px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.owner {
  position: relative;
  margin-top: 20px;
}
.owner1 {
  display: flex;
  align-items: center;
}
.owner-log {
  // width: 500px;
  // background-color: pink;
  max-height: 200px;
  overflow-y: auto;
}
.tiao,
.tiao1,
.tiao2 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 93px;
  left: 20px;
}
.tiao1 {
  position: absolute;
  top: 4px;
  left: 0;
}
.owner2 {
  float: left;
  margin-right: 70px;
  padding-left: 7px;
  position: relative;
}
.tiao2 {
  position: absolute;
  top: 4px;
  left: 0;
}
table,
table tr,
table tr td {
  border: 1px solid #dadfe6;
}
table {
  margin-left: 30px;
  // margin-top: 20px;
  min-height: 25px;
  line-height: 25px;
  border-collapse: collapse;
  padding: 2px;
}
tr {
  height: 50px;
}
td {
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}

.standard1,
.standard,
.standard2 {
  width: 236px;
  height: 50px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}
.standard1,
.price1 {
  background-color: #f8fafc;
}
.standard2 {
  color: #2a3346;
  font-weight: bold;
}
.price,
.price1,
.price2 {
  width: 401px;
  height: 50px;
  padding-right: 20px;
  box-sizing: border-box;
  text-align: right;
}
.price2 {
  color: #2c68ff;
  font-weight: bold;
}

.depart {
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-right: 4px;
}
.depart-main {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.quan,
.quan1 {
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 50%;
  opacity: 1;
  box-sizing: border-box;
}
.quan {
  border: 3px solid #2c68ff;
}
.quan1 {
  border: 3px solid #27cc8b;
}
.site {
  float: left;
  margin-right: 27px;
}

.actual2,
.km2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.actual2 {
  margin-left: 20px;
}
.km2 {
  margin-left: 5px;
}
.map-p {
  margin: 0;
}

.map-box {
  display: flex;
  // width: 670px;
  // height: 300px;
  margin-top: 20px;
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.map-img {
  float: left;
  width: 330px;
  // height: 300px;
  margin-left: 30px;
  margin-right: 20px;
}
.mapright {
  display: flex;
  flex-direction: column;
}

.position,
.position1,
.position2,
.position3 {
  float: left;
  width: 18px;
  height: 20px;
}
.jie1,
.jie2,
.jie3,
.jie4 {
  position: relative;
}
.position {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position1 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position2 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position3 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.map-p {
  margin-bottom: 8px;
}
.main-m,
.main-m1 {
  width: 105px;
  height: 22px;
  text-align: center;
  margin-left: 20px;
  margin-bottom: 8px;
  line-height: 22px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
  background: #e9ebf2;
  opacity: 1;
}
.main-mn {
  width: 129px;
  height: 22px;
  font-size: 12px;
  text-align: center;
  margin-left: 20px;
  line-height: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2c68ff;
  opacity: 1;
  background: #e8f3fe;
  opacity: 1;
}

.btn {
  float: left;
  width: 600px;
  height: 80px;
  opacity: 1;
  margin-left: 55px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.xiubtn {
  width: 600px;
  margin-left: 55px;
  margin-top: 20px;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-right: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.xiangpai {
  width: 80px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 2px;
  border: 0;
}
.cancel:hover {
  color: #2c68ff;
  // background-color: #2c68ff;
}

.car-photo {
  width: 100%;
  height: 128px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  // background-color: fuchsia;
}
.car-p1 {
  float: left;
  margin-right: 30px;
  width: 160px;
  height: 128px;
  background-color: #f5faff;
  margin-top: 10px;
}
.car-img {
  width: 114px;
  height: 69px;
  margin-left: 23px;
  margin-top: 16px;
}
.car-span {
  float: left;
  width: 130px;
  text-align: center;
  margin-left: 15px;
  margin-top: 10px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
.owner-photo {
  width: 100%;
  // height: 200px;
  float: left;
  clear: both;
}

.rizhi {
  margin-left: 30px;
  margin-bottom: 10px;
  display: flex;
}
.rizhileft {
  width: 450px;
  background-color: pink;
  display: flex;
}
.rizhiTit,
.rizhiMain {
  font-size: 14px;
  font-weight: 400;
  color: #2a3346;

  box-sizing: border-box;
  display: inline-block;
}
.rizhiTit {
  width: 250px;
  // width: 180px;
}
.rizhiMain {
  width: 150px;
  display: inline-block;
  margin-left: 10px;
  box-sizing: border-box;
}
.rizhiTxt {
  width: 300px;
  font-size: 14px;
  color: #2c68ff;
  margin-left: 15px;
}

.car-p1 /deep/ .el-image {
  width: 130px;
  height: 90px;
  margin-left: 15px;
  margin-top: 10px;
}

// audio{
//   width: 637px;
//   margin-left: 30px;
//   margin-top: 20px;
// }
audio {
  width: 250px;
  height: 20px;
  vertical-align: middle;
  float: right;
}
</style>
<style lang="less">
.xx {
  width: auto;
  height: auto;
  background: #fff;
}
.xxx {
  padding: 5px 10px;
  line-height: 20px;
  font-size: 12px;
}

.damapbox {
  display: flex;
  flex-direction: column;
}
.ditubox {
  width: 800px !important;
  height: 500px !important;
}
.damap {
  width: 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
}
.mapMain {
  margin-top: 10px;
  padding-left: 30px;
  box-sizing: border-box;
}
.danmain {
  width: 300px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}
.shijian {
  width: 650px;
  margin-left: 30px;
  background: #f8fafc;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}

.xiuinput {
  width: 250px;
  padding-left: 12px;
  box-sizing: border-box;
  height: 50px;
  border: 0;
  outline: none;
  margin-left: 20px;
}
</style>
