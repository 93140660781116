import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Work",
    component: () => import("../views/work.vue"),
  },
  {
    path: "/change",
    name: "",
    component: () => import("../views/changerouter.vue"),
  },
  {
    path: "/Order",
    name: "order",
    component: () => import("../views/Order.vue"),
  },
  {
    path: "/OrderG",
    name: "orderg",
    component: () => import("../views/Ordercopy.vue"),
  },
  {
    path: "/OpPrice",
    name: "OpPrice",
    component: () => import("../views/OpPrice.vue"),
  },
  {
    path: "/Business",
    name: "Business",
    component: () => import("../views/Business.vue"),
  },
  {
    path: "/Vehicle",
    name: "Vehicle",
    component: () => import("../views/Vehicle.vue"),
  },
  {
    path: "/User",
    name: "User",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/Finance",
    name: "Finance",
    component: () => import("../views/Finance.vue"),
  },
  {
    path: "/FinanceG",
    name: "Financeg",
    component: () => import("../views/Financecopy.vue"),
  },
  {
    path: "/Bill",
    name: "Bill",
    component: () => import("../views/Bill.vue"),
  },
  {
    path: "/FinanceNew",
    name: "Finance-new",
    component: () => import("../views/FinanceNew.vue"),
  },
  {
    path: "/Commission",
    name: "Commission",
    component: () => import("../views/Commission.vue"),
  },
  {
    path: "/GroupCompany",
    name: "GroupCompany",
    component: () => import("../views/Commissioncopy.vue"),
  },
  {
    path: "/Maps",
    name: "Maps",
    component: () => import("../views/Maps.vue"),
  },
  {
    path: "/Statistics",
    name: "Statistics",
    component: () => import("../views/Statistics.vue"),
  },
  {
    path: "/set",
    name: "Set",
    component: () => import("../views/set.vue"),
    children: [
      {
        path: "",
        name: "Setting",
        component: () => import("@/views/new/Setting.vue"),
      },
      {
        path: "sports",
        name: "sports",
        component: () => import("@/views/new/sports.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

export default router;
