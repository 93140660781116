<template>
<div @click.stop>
  <div class="boxr" v-show="apps">
    <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt="" />
    <div class="box-main">
      <div class="main-nav">
        <h1>问题反馈</h1>
      </div>
      <div class="feed-item">
        <i class="icon">*</i>
        <span class="title">反馈类型</span>
        <el-radio class="el" v-model="radio1" label="1" size="large"
          >提供需求</el-radio
        >
      </div>
      <div class="feed-item1">
        <i class="icon1">*</i>
        <span class="title1">需求&#12288;&#12288;</span>
        <input type="text" v-model="title"/>
      </div>
      <div class="remark">
        <label class="shu" for="">需求简述</label>
        <el-input
          class="text"
          v-model="content"
          autosize
          type="textarea"
          placeholder="请输入需求简述"
        />
      </div>
      <div class="btn">
        <button class="save" @click="submit">提交</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { ref } from "vue";
import * as api from "../api/index";
const radio1 = ref("1");
const radio2 = ref("1");
const radio3 = ref("1");
const textarea1 = ref("");
export default {
  props:["isfeed"],
  data() {
    return {
      radio1,
      radio2,
      radio3,
      textarea1,
      apps: this.isfeed,
      title:'',
      content:''
    };
  },
  methods: {
    close() {
      this.apps = false;
      Object.assign(this.$data, this.$options.data());
      // this.app = !this.app;
    },
    async submit()
    {
       if(this.title == '' || this.content == '')
       {
          this.$message.warning('请输入具体内容')
       }
       let res = await api.feedback(this.title,this.content);
       if(res.code == 1)
       {
          this.$message.success('提交成功');
          this.close();
       }else{
          this.$message.warning(res.message);
       }
    }
  },
  watch:{
    isfeed(newval){
      console.log('---',newval);
      this.apps = newval
    }
  }
};
</script>

<style lang="less" scoped>
.boxr {
  width: 540px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
  //   background-color: #fff;
  //   background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 500px;
  height: 920px;
  background-color: #fff;
  //   background: orange;
  margin-left: 40px;
  margin-top: 0px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 500px;
  height: 80px;
  border-bottom: 2px solid #fcfcfc;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}

.feed-item {
  height: 19px;
  margin-top: 41px;
  margin-left: 30px;
}
.feed-item1 {
  margin-top: 24px;
  margin-left: 30px;
}
.icon {
  float: left;
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.icon1 {
  float: left;
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-top: 10px;
  margin-left: -2px;
  line-height: 40px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.title {
  float: left;
  // width: 56px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 21px;
  color: #2a3346;
  opacity: 1;
}
.title1 {
  float: left;
  // width: 50px;
  height: 19px;
  font-size: 14px;
  margin-right: 23px;
  margin-left: 2px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
  box-sizing: border-box;
}
.el-radio,
.el-radio--large {
  float: left;
  margin-top: -10px;
}
input {
  width: 260px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 4px;
}
input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}


.remark {
  margin-left: 32px;
  margin-top: 24px;
}
.shu {
  float: left;
  // width: 50px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  margin-right: 26px;
  color: #2a3346;
  opacity: 1;
  box-sizing: border-box;
}
.text {
  width: 260px;
  vertical-align: top;
}
.btn {
  margin-left: 115px;
  margin-top: 40px;
}
.save {
  width: 80px;
  height: 32px;
  border: none;
  background: #2c68ff;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
.xiao {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 4px;
  cursor: pointer;
}
.xiao:hover{
  color: #2c68ff;
  border:1px solid #2c68ff;
}
</style>
