<template>
  <div class="searchBox">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="searchTit">线路及价格预设</p>
      <!-- 合作单位 -->
      <div class="yuitem" v-if="isreadonly">
        <label class="yulabel" for="">合作单位</label>
        <input class="yuinput" type="text" v-model="hezuo" readonly />
      </div>
      <!-- 任务点 -->
      <div class="yuitem ding" v-if="isreadonly">
        <label class="yulabel" for="">任务点&#12288;</label>
        <input
          class="yuinput"
          v-model="address"
          id="address"
          @blur="blur"
          type="text"
          autocomplete="off"
          @change="intaddress"
        />
        <img
          @click="renWei(1)"
          class="mapImg"
          src="../assets/img/cd/dingwei.png"
          alt=""
        />
        <div class="select-panel" v-show="isShow">
          <ul>
            <li
              class="der"
              v-for="(item, index) in words"
              :key="index"
              @click="map(index)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <input
          class="input-norm1 yuinput"
          type="hidden"
          v-model="addressLnglat"
        />
      </div>
      <!-- 目的地 -->
      <div class="yuitem ding" v-if="isreadonly">
        <label class="yulabel" for="">目的地&#12288;</label>
        <input
          class="yuinput"
          v-model="destination"
          @blur="blur2"
          type="text"
          autocomplete="off"
          @change="intaddress"
        />

        <img
          @click="renWei(2)"
          class="mapImg"
          src="../assets/img/cd/dingwei.png"
          alt=""
        />

        <div class="select-panel" v-show="isShow2">
          <ul>
            <li
              class="der"
              v-for="(item, index) in words2"
              :key="index"
              @click="map2(index)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <input
          class="input-norm1 yuinput"
          type="hidden"
          v-model="destinationLnglat"
        />
      </div>

      <div class="yuitem" v-if="isreadonly">
        <div class="juli">
          当前
          <strong>{{ distance }}</strong>
          公里
        </div>
        <!-- 即时单 -->
        <div class="jishi">
          <label class="priceTit" for="">即时单</label>
          <input class="jishiint" v-model="price" />
          <span class="yuan">元</span>
        </div>
        <!-- 预约单 -->
        <div class="jishi">
          <label class="priceTit" for="">预约单</label>
          <input class="jishiint" v-model="price2" />
          <span class="yuan">元</span>
        </div>
        <!-- 顺路单 -->
        <div class="jishi">
          <label class="priceTit" for="">顺路单</label>
          <input class="jishiint" v-model="price3" />
          <span class="yuan">元</span>
        </div>

        <!-- <label class="yulabel" for="">一口价&#12288;</label>
        <input class="bite" type="text" v-model="yikouprice" />元 -->

        <!-- <button :class="selectIndex !== '' ? 'xiubtn' : 'yubtn'" @click="add">
          {{ selectIndex !== "" ? "修改" : "添加" }}
        </button> -->
        <button class="xiubtn" @click="xiu($event)" v-show="yinbtn">
          修改
        </button>
        <button class="yubtn" @click="add" v-show="!yinbtn">添加</button>
      </div>

      <div class="yuxinBox" ref="newbox">
        <!-- 信息 -->
        <table class="yutable" border="1" cellspacing="0">
          <tr class="yutit">
            <th class="renwu">任务点</th>
            <th class="mudi">目的地</th>
            <th class="yikou">即时单/预约单/顺路单</th>
            <th class="operation">操作</th>
          </tr>
          <template v-if="xinxiList.length">
            <tr
              :class="
                (index % 2 == 0 && !changecolor) ||
                (index % 2 !== 0 && changecolor)
                  ? 'bg_color'
                  : 'bg_color2'
              "
              v-for="(item, index) in xinxiList"
              :key="index"
            >
              <td>{{ item.address }}</td>
              <td>{{ item.destination }}</td>
              <td>{{ item.price }}/{{ item.price2 }}/{{ item.price3 }}</td>
              <td>
                <button
                  class="gai"
                  @click="update(item, index, $event)"
                  :data-id="item.id"
                  v-if="isreadonly"
                >
                  修改
                </button>
                <button
                  class="shan"
                  @click="del(index, $event)"
                  :data-id="item.id"
                  v-if="isreadonly"
                >
                  删除
                </button>
                <!-- <button class="gai" v-if="!isreadonly" @click="xuan(item)">
                  选择
                </button> -->
                <el-button
                  link
                  type="primary"
                  size="small"
                  v-if="!isreadonly"
                  @click="xuan(item)"
                >
                  选择
                </el-button>
              </td>
            </tr>
          </template>
        </table>
      </div>

      <!-- 按钮 -->
      <div class="btn" v-if="isreadonly">
        <button class="set" @click="bao">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>

    <yusearch
      :isyuWei="yuweizhi"
      :isyuaddr="yuisaddrs"
      :foundaddryu="foundaddryu1"
      :foundlnglatyu="foundlnglatyu1"
      @addr1="yuaddr"
      @addr1lnglat="yuaddrlnglat"
      @addr2="yudest"
      @addr2lnglat="yudestlnglat"
      @hide="hide"
    ></yusearch>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import * as api from "../api/index";
import search from "./search.vue";
import Yusearch from "./yusearch.vue";
import { fa } from "element-plus/lib/locale";
export default {
  components: { search, Yusearch },
  props: ["isyu", "yusheTitP", "yushecid", "isreadonly", "xuandan"],
  data() {
    return {
      yuweizhi: false,
      yuisaddrs: "",
      foundaddryu1: "",
      foundlnglatyu1: "",
      yushes: this.isyu,
      xinxiList: [],
      price: "",
      price2: "",
      prive3: "",

      hezuo: this.yusheTitP,
      address: "",
      destination: "",
      addressLnglat: "",
      destinationLnglat: "",
      oldaddressLnglat: "",
      olddestinationLnglat: "",
      selectIndex: "",
      isShow: false,
      words: [],
      isShow2: false,
      words2: [],
      ycid: "",
      distance: "",
      id: "",
      flag: "",

      yinbtn: false,
      isreadonly: this.isreadonly,
    };
  },

  created() {
    this.getlist();
    console.log("打印判断---", this.isreadonly);
    console.log("判断选单---", this.xuandan);

    this.$nextTick(() => {
      if (this.isreadonly == false) {
        this.$refs.newbox.classList.add("foundyuxinBox");
        console.log("盒子盒子：", this.$refs.newbox);
      }
    });
  },

  methods: {
    close() {
      this.$emit("closeyu");
    },
    hide() {
      this.yuweizhi = false; // 关闭地图组件
    },

    intaddress() {
      console.log("输入框经纬度：", this.addressLnglat, this.destinationLnglat);
    },

    renWei(id) {
      this.yuweizhi = true;
      this.yuisaddrs = id;
      if (this.yuisaddrs == 1) {
        this.foundaddryu1 = this.address;
        this.foundlnglatyu1 = this.addressLnglat;
        console.log("任务点1输入地址1:", this.foundaddryu1);
        console.log("任务点1输入地址2:", this.foundlnglatyu1);
      } else {
        this.foundaddryu1 = this.destination;
        this.foundlnglatyu1 = this.destinationLnglat;
        console.log("目的地2输入地址1:", this.foundaddryu1);
        console.log("目的地2输入地址2:", this.foundlnglatyu1);
      }
    },

    yuaddr(addr1) {
      this.oldaddressLnglat = addr1;
      this.address = addr1;
      this.isShow = false;
    },
    yuaddrlnglat(addr1lnglat) {
      this.addressLnglat = addr1lnglat;
    },
    yudest(addr2) {
      this.olddestinationLnglat = addr2;
      this.destination = addr2;
      this.isShow2 = false;
    },
    yudestlnglat(addr2lnglat) {
      this.destinationLnglat = addr2lnglat;
    },

    // 添加
    async add() {
      console.log("点击添加----");
      let data = {
        cid: this.yushecid,
        address: this.address,
        destination: this.destination,
        address_lnglat: this.addressLnglat,
        destination_lnglat: this.destinationLnglat,
        price: this.price,
        price2: this.price2,
        price3: this.price3,
      };
      let res = await api.added(data);
      console.log("新增预设线路----", res.data);
      this.$message.success("添加成功");
      this.getlist();
    },

    // 修改
    xiu() {
      ElMessageBox.confirm("是否确定修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = {
            cid: this.yushecid,
            address: this.address,
            destination: this.destination,
            address_lnglat: this.addressLnglat,
            destination_lnglat: this.destinationLnglat,
            price: this.price,
            price2: this.price2,
            price3: this.price3,
            id: this.id,
          };
          let xiures = await api.xiugai(data);
          console.log("修改数据---", xiures);
          this.$message.success("修改成功");
          this.getlist();
          this.id = "";
          this.price = "";
          this.price2 = "";
          this.price3 = "";
          this.address = "";
          this.destination = "";
          this.addressLnglat = "";
          this.oldaddressLnglat = "";
          this.destinationLnglat = "";
          this.olddestinationLnglat = "";
          this.distance = "";
        })
        .catch(() => {
          this.$message.info("取消修改");
        });
      this.yinbtn = false;
    },

    //修改
    update(item, index, eve) {
      this.id = eve.target.dataset.id;
      console.log("修改id", this.id);
      this.selectIndex = index;
      this.address = item.address;
      this.oldaddressLnglat = item.address;
      this.addressLnglat = item.address_lnglat;
      this.destination = item.destination;
      this.olddestinationLnglat = item.destination;
      this.destinationLnglat = item.destination_lnglat;
      this.price = item.price;
      this.price2 = item.price2;
      this.price3 = item.price3;
      this.yinbtn = true;
    },
    //删除
    del(index, eve) {
      console.log("删除", eve.target.dataset.id);
      ElMessageBox.confirm("是否确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let delxin = await api.delyushe(eve.target.dataset.id, this.ycid);
          console.log("删除成功--", delxin);
          this.$message.success("删除成功");
          this.getlist();
        })
        .catch(() => {
          this.$message.info("取消删除");
        });
    },
    // 选择
    xuan(item) {
      console.log(
        "选中地址-----",
        item.address_lnglat,
        item.destination_lnglat
      );
      this.$emit("yuren", item.address);
      this.$emit("yurenlnglat", item.address_lnglat);
      this.$emit("yumu", item.destination);
      this.$emit("yumulnglat", item.destination_lnglat);
      this.$emit("preline",item);
      // 判断选中的订单类型
      if (this.xuandan == 1) {
        this.$emit("yuprice", item.price);
      } else if (this.xuandan == 2) {
        this.$emit("yuprice", item.price3);
      } else if (this.xuandan == 3) {
        this.$emit("yuprice", item.price2);
      }
      this.close();
    },

    async getlist() {
      let res = await api.Original(this.yushecid);
      this.xinxiList = res.data;
    },
    // 规划公里
    async gui() {
      let guiKm = await api.guihua(this.addressLnglat, this.destinationLnglat);
      this.distance = guiKm.data.distance;
      console.log("规划公里---", guiKm);
    },

    // 任务点输入框
    blur() {
      setTimeout(() => {
        this.isShow = false;
        console.log("失去焦点----");
      }, 200);
    },
    async search(newval) {
      this.newvals = newval;
      this.addressLnglat = "";
      let res = await api.workMap(this.newvals);
      this.words = res.data;
      if (this.words.length > 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    map(query) {
      this.oldaddressLnglat = this.words[query].value;
      this.address = this.words[query].value;
      this.addressLnglat = this.words[query].location;
      this.isShow = false;
    },
    // 目的地输入框
    blur2() {
      setTimeout(() => {
        this.isShow2 = false;
        console.log("失去焦点----");
      }, 200);
      // this.gui();
    },
    async search2(newval) {
      this.newvals2 = newval;
      this.destinationLnglat = "";
      let res2 = await api.workMap(this.newvals2);
      this.words2 = res2.data;
      if (this.words2.length > 0) {
        this.isShow2 = true;
      } else {
        this.isShow2 = false;
      }
    },
    map2(query) {
      this.olddestinationLnglat = this.words2[query].value;
      this.destination = this.words2[query].value;
      this.destinationLnglat = this.words2[query].location;
      this.isShow2 = false;
    },
  },

  watch: {
    yushes(newval) {
      console.log(2);
      this.yushes = newval;
      this.getlist();
    },
    address(newval) {
      if (newval == this.oldaddressLnglat) {
        this.isShow = false;
        return;
      }
      this.addressLnglat = "";
      if (newval == "") {
        this.isShow = false;
        return false;
      }
      this.search(newval);
      console.log("this.search发送", newval);

      if (this.addressLnglat == "") {
        this.search(newval);
      }
      // this.address_lnglat = this.addressLnglat;
      // this.destination_lnglat = this.destinationLnglat;
      this.gui();
    },
    destination(newval) {
      console.log("------", newval);
      if (newval == this.olddestinationLnglat) {
        this.isShow2 = false;
        return;
      }
      this.destinationLnglat = "";
      if (newval == "") {
        this.isShow2 = false;
        return false;
      }
      if (this.destinationLnglat == "") {
        this.search2(newval);
      }
      // this.address_lnglat = this.addressLnglat;
      // this.destination_lnglat = this.destinationLnglat;
    },
    addressLnglat(newval) {
      console.log("目的地", newval);
      if (this.destinationLnglat != "" && newval != "") {
        this.gui();
      }
    },
    destinationLnglat(newval) {
      console.log("任务点", newval);
      if (this.addressLnglat != "" && newval != "") {
        this.gui();
      }
    },
    async yushecid(newval) {
      console.log(111);
      this.ycid = newval;
    },
    yusheTitP(newval) {
      console.log(222);
      console.log("======", newval);
      this.hezuo = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 640px;
  background-color: skyblue;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
.searchMain {
  width: 640px;
  height: 920px;
  background-color: #fff;
  padding-top: 40px;
  padding-left: 30px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 65px;
  left: -40px;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  font-weight: bold;
  box-sizing: border-box;
}
.yuitem {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.yulabel {
  margin-right: 20px;
  display: inline-block;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  //   line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.yuinput {
  width: 440px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
  border: 1px solid #e0e0e0;
}
.yuinput::-webkit-input-placeholder {
  color: #c0c4cc;
}
.mapImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
}
.add {
  float: left;
  width: 30px;
  height: 32px;
  margin-left: 50px;
  cursor: pointer;
}
// .bite {
//   width: 380px;
//   margin-right: 10px;
// }
.yuxinBox {
  width: 580px;
  max-height: 400px;
  margin-top: 24px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
}
.yuxinBox::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
.foundyuxinBox {
  max-height: 800px;
}
.yutable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.yutable td {
  text-align: center;
}
.yubtn {
  width: 50px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 20px;
  border: 0;
  cursor: pointer;
}
.xiubtn {
  width: 50px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.btn {
  margin-top: 46px;
  // padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.yutit {
  height: 32px;
  background-color: #f5f5f5;
}
// .yutxt {
//   height: 50px;
// }
.gai,
.shan {
  width: 32px;
  height: 20px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
.gai {
  background-color: #2c68ff;
  color: #fff;
}
.shan {
  margin-left: 10px;
}
.renwu,
.mudi {
  width: 160px;
}
.yikou {
  width: 125px;
}
.operation {
  width: 130px;
}
.select-panel {
  // height: 100px;
  position: absolute;
  top: 35px;
  margin-left: 80px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
.input-norm1 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  border-right: 0;
}
.ding {
  position: relative;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  height: 50px;
  background-color: #ffffff !important;
}
.bg_color2 {
  height: 50px;
  background-color: #fafafa !important;
}
.jishi {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 12px;
}
.jishiint {
  width: 80px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
  // text-align: center;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  outline: none;
}
.yuan {
  width: 32px;
  height: 30px;
  box-sizing: border-box;
  background-color: #f7f9fb;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.priceTit,
.juli {
  font-size: 14px;
}
</style>
