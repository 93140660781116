<template>
  <div class="splitbox">
    <div class="main">
      <div class="splitnav">
        <div class="splitit">订单拆分</div>
        <div class="option">
          <div class="optiontit" @click="splitjian" ref="jian">减少拆分 -</div>
          <div class="optiontit optionactive" @click="splitadd" ref="jia">
            增加拆分 +
          </div>
        </div>
      </div>
      <ul class="splitmain">
        <li class="splitxinxi" v-for="(item, index) in splitList" :key="index">
          <div class="splitxinxitit" v-if="index == 0">原订单:</div>
          <div class="splitxinxitit" v-else>拆分订单{{index}}:</div>
          <div class="splitint" v-if="detail.chargeType == 1">
            <span class="splitinttxt">一口价</span>
            <input class="chaint" type="text" v-model="item.price" />
            <span class="chaiyuan">元</span>
          </div>
          <div class="splitint" v-else-if="detail.chargeType == 2">
            <span class="splitinttxt">公里价</span>
          </div>
        </li>
      </ul>
      <div class="splitbtn">
        <button class="xiao" @click="close" >取消</button>
        <button class="save" @click="qure" v-if="btndisabled == false" disabled>确定</button>
        <button class="save" @click="qure" v-if="btndisabled == true">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api1 from "../api/orderDetail";
import { ElMessageBox } from "element-plus";
export default {
  props: ["splitids"],
  data() {
    return {
      splitid:this.splitids,
      detail:'',
      yinsplit: true,
      splitprice: "",
      btndisabled:true,
      splitList: [
        {
          price: 0,
        },
        {
          price: 0,
        },
      ],
    };
  },
  async created(){
    if(this.splitid != '')
    {
       let res = await api1.OrderDetailM(this.splitid);
       this.detail = res.data;
       if(this.detail.chargeType == 1)
       {
         this.splitList[0].price = this.detail.guidePrice;
       }
    }
  },
  methods: {
    close(){
      this.$emit("close");
    },
    async qure() {
      this.btndisabled = false;
       ElMessageBox.confirm("确认拆分订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.OrderOpen(this.splitid,this.splitList);
          if(res.code == 1)
          {
            this.$message.success('拆分成功');
            this.btndisabled = true;
            this.$emit("close2");
          }else{
            this.btndisabled = true;
            this.$message.info(res.message);
          }
        })
        .catch(() => {
          this.btndisabled = true;
          this.$message.info("取消拆分");
        });

    },
    splitadd() {
      this.splitList.push({price:0});
      this.$refs.jian.classList.add("optionactive");
    },
    splitjian() {
      if (this.splitList.length >= 3) {
        this.splitList.pop();
      } else {
        this.$refs.jian.classList.remove("optionactive");
        return;
      }
    },
  },
  watch:{
    
  }
};
</script>

<style lang="less" scoped>
.splitbox {
 float: left;
 width: 1920px;
 height: 940px;
 background: #000000;
 background: rgba(0, 0, 0, 0.4);
 border-radius: 0px;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 70;
}
.main {
  width: 520px;
  //   height: 310px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  padding: 28px;
  box-sizing: border-box;
}
.splitnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.option {
  display: flex;
  align-items: center;
}
.splitit {
  font-size: 20px;
  color: #171746;
  font-weight: bold;
}
.optiontit {
  font-size: 14px;
  color: #b5b5be;
  margin-left: 28px;
  cursor: pointer;
}
.optionactive {
  color: #2c68ff;
}
.splitmain {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
.splitxinxi {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.splitxinxitit,
.splitint {
  font-size: 14px;
  color: #44444f;
}
.splitint {
  position: relative;
  width: 180px;
  height: 32px;
  line-height: 32px;
}
.chaint {
 width: 92px;
 height: 36px;
 border: 1px solid #e2e2ea;
 outline: none;
 padding-left: 12px;
 box-sizing: border-box;
 margin-left: 16px;
}
.chaiyuan { 
width: 37px;
 height: 34px;
 background-color: #fafafb;
 font-size: 14px;
 text-align: center;
 line-height: 34px;
 position: absolute;
 right: 1px;
 border: 1px solid #e2e2ea;
 border-left: 0;
}
.splitbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
  margin-left: 20px;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
</style>