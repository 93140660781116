<template>
  <div class="Snatchbox">
    <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
    <div class="snatchmain">
      <div class="snatchnav">
        <div class="snatchnavleft">
          <img class="qiangimg" src="../assets/img/cd/gong.png" alt="" />
          共享单
        </div>
        <div class="snatchnavright" @click="getsharelist()">
          <img class="shuaimg" src="../assets/img/ding/shua.png" alt="" />
          刷新
        </div>
      </div>

      <div class="snatchxinxi" v-for="(item, index) in snatchList" :key="index">
        <div class="xinxinav">
          <!-- {{ item.rescueType }} -->
          <div class="xinxinavleft">
            <div class="xinxileft">
              <img class="xinxiImg" src="../assets/img/cd/tuo.png" alt="" />
              <div class="qiangtype" v-if="item.rescueStyle == 1">即时单</div>
              <div class="qiangtype2" v-if="item.rescueStyle == 2">预约单</div>
              <div class="qiangtype3" v-if="item.rescueStyle == 3">顺路单</div>
              <div class="yugu"></div>
              预估：
              <i class="yugukm">{{ item.plandistance }}公里</i>
            </div>
            <div class="xinxiright">
              <div class="carxing">{{ item.cartype }}</div>
              <div class="carxing">{{ item.needcar }}</div>
            </div>
          </div>
          <div class="xinxinavright">
            <i class="yuguprice">{{ item.planmoney }}元</i>
          </div>
        </div>
        <div class="xinxidizhi">
          <div class="qidian">
            <div class="qibox">
              <img class="qimg" src="../assets/img/cd/qi.png" alt="" />
            </div>
            <div class="qiaddress">
              <span class="qitit">{{ item.addresstit }}</span>
              <span class="qitxt">{{ item.address }}</span>
            </div>
          </div>
          <div class="zhongdian">
            <div class="qibox">
              <img class="qimg" src="../assets/img/cd/zhong.png" alt="" />
            </div>
            <div class="qiaddress">
              <span class="qitit">{{ item.destinationtit }}</span>
              <span class="qitxt">{{ item.destination }}</span>
            </div>
          </div>

          <div class="xinxibottom">
            <div class="xinxibottomleft">
              <div>救援时间：{{ item.rescue_time }}</div>
            </div>
            <div class="snatchbtn" @click="qiang(item.rescue_id)">抢单</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/order.js";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      snatchList: [],
      btndisabled: false,
    };
  },
  created() {
    this.getsharelist();
  },
  methods: {
    close() {
      this.$emit("guansnatch");
    },
    async getsharelist() {
      let res = await api.getShareList();
      this.snatchList = res.data;
      console.log("抢单内容", this.snatchList);
    },
    async qiang(id) {
      ElMessageBox.confirm("确认抢此订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api.qiangOrder(id);
          if (res.code == 1) {
            this.$message.success("抢单成功");
            this.btndisabled = true;
            this.getsharelist();
          } else {
            this.btndisabled = true;
            this.$message.info(res.message);
            this.getsharelist();
          }
        })
        .catch(() => {
          this.btndisabled = true;
          this.$message.info("取消抢单");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Snatchbox {
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;
  // padding: 28px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.snatchmain {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 940px;
  float: right;
  opacity: 1;
  background-color: #fff;
  padding: 28px;
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.qiangimg {
  width: 42px;
  height: 20px;
  margin-right: 12px;
}
.shuaimg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.snatchnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.snatchnavleft,
.snatchnavright {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: #fafafb;
}
.snatchnavleft {
  color: #44444f;
}
.snatchnavright {
  color: #2c68ff;
  cursor: pointer;
}

.snatchxinxi {
  width: 100%;
  // height: 400px;
  background-color: #fafafb;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 28px;
}
.xinxinav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.carxing {
  padding: 2px 12px;
  box-sizing: border-box;
  font-size: 14px;
  color: #2c68ff;
  background-color: #edf2ff;
  border-radius: 4px;
  margin-right: 8px;
  font-weight: normal;
}
.xinxinavleft {
  display: flex;
  // align-items: center;
  flex-direction: column;
  font-size: 16px;
  color: #44444f;
  font-weight: bold;
}
.xinxinavright {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #92929d;
}
i {
  font-style: normal;
}
.yugu {
  font-size: 12px;
  color: #92929d;
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.xinxileft {
  display: flex;
  align-items: center;
}
.xinxiright {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-top: 12px;
}
.yugukm {
  font-size: 14px;
  color: #44444f;
  margin-right: 12px;
}
.yuguprice {
  font-weight: bold;
  font-size: 24px;
  color: #ff525c;
}
.xinxiImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.qiangtype,
.qiangtype2,
.qiangtype3 {
  font-size: 14px;
  padding: 2px 5px;
  font-weight: normal;
}
.qiangtype {
  color: #2c68ff;
  background-color: #e8f3fe;
}
.qiangtype2 {
  color: #27cc8b;
  background: #eefbf4;
}
.qiangtype3 {
  color: #fff6e9;
  background: #ff9100;
}
.xinxidizhi {
  margin-top: 20px;
}
.qidian,
.zhongdian {
  display: flex;
}
.zhongdian {
  margin-top: 16px;
}
.qimg {
  width: 20px;
  height: 24px;
  margin-right: 10px;
}
.qiaddress {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.qitit {
  font-size: 14px;
  color: #44444f;
}
.qitxt {
  font-size: 14px;
  color: #92929d;
  margin-top: 4px;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.xinxibottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  background-color: #ffedee;
  padding-left: 12px;
  box-sizing: border-box;
}
.xinxibottomleft {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #ff525c;
  font-weight: bold;
}
.snatchbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 34px;
  background-color: #2c68ff;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
</style>