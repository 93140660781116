import content from "element-plus/lib/components/popper/src/content";
import http from "../util/http.js";

// 合作商列表
// /service-provider/list

export const joinList = (data) => {
  let { page, perpage, keyword,saleman,companytype,status } = data;
  return http
    .get("/service-provider/list", {
      params: {
        page,
        perpage,
        keyword,
        saleman,
        companytype,
        status
      },
    })
    .then((res) => res.data);
};

// 合作商添加
// /service-provider/do-add
export const joinAdd = (
  name,
  address,
  tel,
  contact,
  addressLnglat,
  remark,
  short,
  companytype,
  brand,
  piao,
  saleman,
  huixuan,
  contactxuan,
  carprice,
  groupid,
  plancal,
  ocrcarnumber,
  ocrcarvin,
  isrescue
) => {
  return http
    .post("/service-provider/do-add", {
      name: name,
      address: address,
      tel: tel,
      contact: contact,
      addressLnglat: addressLnglat,
      remark: remark,
      short: short,
      companytype: companytype,
      brand: brand,
      piao: piao,
      saleman:saleman,
      is_need_return:huixuan,
      is_need_tel:contactxuan,
      carprice:carprice,
      group_id:groupid,
      plancal:plancal,
      ocrcarnumber:ocrcarnumber,
      ocrcarvin:ocrcarvin,
      isrescue:isrescue
    })
    .then((res) => res.data);
};

export const joinupdate = (
  cid,
  name,
  address,
  tel,
  contact,
  addressLnglat,
  remark,
  short,
  companytype,
  brand,
  piao,
  saleman,
  img88,
  huixuan,
  contactxuan,
  carprice,
  groupid,
  plancal,
  ocrcarnumber,
  ocrcarvin,
  automationtype,
  auto_x,
  auto_y,
  auto_z,
  auto_start,
  auto_end,
  isrescue,
  js,
  yy,
  sl
) => {
  return http
    .post("/service-provider/do-edit", {
      cid: cid,
      name: name,
      address: address,
      tel: tel,
      contact: contact,
      addressLnglat: addressLnglat,
      remark: remark,
      short: short,
      companytype: companytype,
      brand: brand,
      piao: piao,
      saleman:saleman,
      img88:img88,
      is_need_return:huixuan,
      is_need_tel:contactxuan,
      carprice:carprice,
      group_id:groupid,
      plancal:plancal,
      ocrcarnumber:ocrcarnumber,
      ocrcarvin:ocrcarvin,
      automationtype:automationtype,
      auto_x:auto_x,
      auto_y:auto_y,
      auto_z:auto_z,
      auto_start:auto_start,
      auto_end:auto_end,
      isrescue:isrescue,
      js:js,
      yy:yy,
      sl:sl
    })
    .then((res) => res.data);
};

// 合作商服务价格
// service-provider/price    cid，对应服务号
export const priceList = (cid, id) => {
  return http
    .get("/service-provider/price", {
      params: {
        cid,
        id,
      },
    })
    .then((res) => res.data);
};

// 合作商+号
// service-provider/add-price
export const priceAdd = (
  cid,
  rescueType,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  guaKm,
  maxPrice,
) => {
  // let { guidePrice, guideKm, KmPrice, guaKm } = obj;
  return http
    .post("/service-provider/add-price", {
      cid: cid,
      rescueType: rescueType,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      guaKm: guaKm,
      maxPrice:maxPrice,
    })
    .then((res) => res.data);
};

export const priceDel = (id, cid, rescueType) => {
  // let { guidePrice, guideKm, KmPrice, guaKm } = obj;
  return http
    .post("/service-provider/del-price", {
      id: id,
      cid: cid,
      rescueType: rescueType,
    })
    .then((res) => res.data);
};

// 合作商保存
// service-provider/update-price
export const priceSave = (
  cid,
  id,
  isGua,
  isNightPrice,
  nightBegin,
  nightEnd,
  nightPrice,
  bridgestyle,
) => {
  return http
    .post("/service-provider/update-price", {
      cid,
      rescueType: id,
      isGua,
      isNightPrice,
      nightBegin,
      nightEnd,
      nightPrice,
      bridgestyle,
    })
    .then((res) => res.data);
};

// 合作商详情
export const jionDetail = (cid) => {
  return http.get("/service-provider/detail", {
    params: {
      cid,
    },
  });
};

export const doenable = (cid) => {
  return http
    .post("/service-provider/do-enable", {
      cid: cid,
    })
    .then((res) => res.data);
};

export const dodisable = (cid) => {
  return http
    .post("/service-provider/do-disable", {
      cid: cid,
    })
    .then((res) => res.data);
};

// 合作商地下员工列表
// /service-provider/user
export const manageList = (cid,keyword) => {
  return http
    .get("/service-provider/user", {
      params: {
        cid: cid,
        keyword:keyword
      },
    })
    .then((res) => res.data);
};

export const manageMarketing = (uid) => {
  return http
    .get("/user/commission-detail", {
      params: {
        uid: uid,
      },
    })
    .then((res) => res.data);
};

export const commission = (uid, type, commission) => {
  return http
    .post("/service-provider/user-commission", {
      uid: uid,
      commissionType: type,
      commission: commission,
    })
    .then((res) => res.data);
};

export const partnercommission = (cid) => {
  return http
    .post("/service-provider/partner-commission", {
      cid: cid,
    })
    .then((res) => res.data);
};

export const updatecommission = (cid, checkList, list1) => {
  return http
    .post("/service-provider/update-commission", {
      cid: cid,
      checkList: checkList,
      list1: list1,
    })
    .then((res) => res.data);
};

export const rechargeaccount = (cid, money, type) => {
  return http
    .post("/service-provider/recharge", {
      cid: cid,
      money: money,
      type: type,
    })
    .then((res) => res.data);
};

// 当前空驶规则
export const getRule = (cid) => {
  return http
    .get("/service-provider/get-empty-rule-new", {
      params:{
        cid,
      }
    })
    .then((res) => res.data);
};

// 提交规则
export const setRule = (cid,type,x,y,z) => {
  return http
    .post("/service-provider/update-empty-rule", {
      cid,
      type,
      x,
      y,
      z,
    })
    .then((res) => res.data);
};

export const getCommission = (data) => {
    let { page, perpage, keyword, createTimeBegin, createTimeEnd,finishTimeBegin, finishTimeEnd,rescueTimeBegin, rescueTimeEnd,carnumber } = data;
        return http
        .get("/order/commission-list", {
            params:{
            page,
            perpage,
            keyword,
            createTimeBegin,
            createTimeEnd,
            finishTimeBegin,
            finishTimeEnd,
            rescueTimeBegin,
            rescueTimeEnd,
            carnumber
            }
        })
    .then((res) => res.data);
  };

  export const xiugaietc = (id,money) => {
    return http
      .post("/order/change-etc", {
        id,
        money
      })
      .then((res) => res.data);
  };

  export const xiugaiCom = (id,money) => {
    return http
      .post("/order/change-com", {
        id,
        money
      })
      .then((res) => res.data);
  };
  

  export const setRule2 = (cid,type,a,b,c,d,x,y,z) => {
    return http
    .post("service-provider/update-empty-rule-x", {
     cid,
     type,
     a,
     b,
     c,
     d,
     x,
     y,
     z,
  })
  .then((res) => res.data);
    };


  export const getGrouplist = () => {

        return http
        .get("/service-provider/get-group-list", {
            params:{
            }
        })
    .then((res) => res.data);
  };


  export const getBill = () => {

    return http
    .get("/sys/bill", {
        params:{
        }
    })
.then((res) => res.data);
};


  export const priceAddNew = (
    cid,
    rescueType,
    chargeType,
    guidePrice,
    guideKm,
    kmPrice,
    guaKm,
    is_gua,
    carType
  ) => {
    // let { guidePrice, guideKm, KmPrice, guaKm } = obj;
    return http
      .post("/service-provider/add-price-car", {
        cid: cid,
        rescueType: rescueType,
        chargeType: chargeType,
        guidePrice: guidePrice,
        guideKm: guideKm,
        kmPrice: kmPrice,
        guaKm: guaKm,
        isGua:is_gua,
        carType:carType
      })
      .then((res) => res.data);
  };

  export const updatecommissionnew = (cid, checkList, list1,list2,list3) => {
    return http
      .post("/service-provider/update-commission-new", {
        cid: cid,
        checkList: checkList,
        list1: list1,
        list2: list2,
        list3: list3,
      })
      .then((res) => res.data);
  };


  export const partnercommissionnew = (cid) => {
    return http
      .post("/service-provider/partner-commission-new", {
        cid: cid,
      })
      .then((res) => res.data);
  };



  export const estimate = (data) => {
    let { cid, lng, lat, dlng, dlat, price, km, per, needcar } = data;
        return http
      .get("/order/plan-new-x", {
      params: {
      cid,
      lng,
      lat,
      dlng,
      dlat,
      price,
      km,
      per,
      needcar,
      },
      })
      .then((res) => res.data);
    };


    export const setRule3= (cid,type,list) => {
      return http
      .post("service-provider/empty-rule-five", {
       cid,
       type,
       list
    })
    .then((res) => res.data);
      };