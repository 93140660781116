<template>
  <div class="beiBox">
    <div class="main">
      <div class="beiNav">
        <h1>车牌车架号修改</h1>
        <img
          class="cha"
          @click="close"
          src="../assets/img/index/cha.png"
          alt=""
        />
      </div>

      <div class="beiMain">
        <div class="hezuo">
          <div class="carbox">
            <span class="cartit">订单号:</span>
            <span class="cartxt2">{{ iscarnum }}</span>
          </div>
          <div class="carbox">
            <span class="cartit">救援时间:</span>
            <span class="cartxt2">{{ carmain.time }}</span>
          </div>
        </div>
        <div class="carbox">
          <span class="cartit">合作商:</span>
          <span class="cartxt">{{ carmain.hezuotit }}</span>
        </div>

        <div class="carbox">
          <span class="cartit">任务点:</span>
          <span class="cartxt">{{ carmain.renwu }}</span>
        </div>
        <div class="carbox">
          <span class="cartit">目的地:</span>
          <span class="cartxt">{{ carmain.mudi }}</span>
        </div>
        <div class="carbox">
          <span class="cartit">车牌车架号:</span>
          <input
            class="carint"
            type="text"
            v-model="carxiu"
            placeholder="请输入车牌车架号"
          />
        </div>
      </div>

      <div class="btn">
        <button class="save" @click="bao">保存</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/order";
export default {
  props: ["iscarnum", "carmain"],
  data() {
    return {
      carxiu: "",
    };
  },
  created() {
    this.carxiu = this.carmain.chejia;
  },
  methods: {
    close() {
      this.$emit("overframe");
    },
    async bao() {
      let res = await api.carframe(this.iscarnum, this.carxiu);
      //console.log("修改成功", res);
      if (res.code == 1) {
        this.close();
      }else{
        this.$message.warning(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.beiBox {
  float: left;
  width: 1920px;
  height: 940px;
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 70;
}
.main {
  width: 520px;
  height: 310px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  box-sizing: border-box;
}
.beiNav {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  padding: 0 10px;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
}
h1 {
  float: left;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 500;
  color: #2a3346;
}
.cha {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 17px;
}
label {
  font-size: 16px;
}

.beiMain {
  //   display: flex;
  margin-left: 30px;
  margin-top: 10px;
}
.hezuo {
  display: flex;
  width: 450px;
  justify-content: space-between;
}
.cartxt {
  width: 400px;
}
.cartit {
  display: inline-block;
  width: 80px;
}
.carbox {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
  align-items: center;
}

.btn {
  margin-left: 90px;
  margin-top: 10px;
  float: left;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}

.carint {
  width: 260px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
  border: 1px solid #e0e0e0;
}
.carint::-webkit-input-placeholder {
  color: #c0c4cc;
}
</style>
