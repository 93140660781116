<template>
  <div @click.stop>
    <div class="cancelBox" v-show="applyCancel">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="cancelMain">
        <p class="cancelTit">取消申请</p>

        <!-- 车主信息  技师信息 -->
        <div class="information">
          <div class="informationLeft">
            <div class="informationTit">车主信息</div>
            <div class="carBox">
              <label class="carTxt" for="">姓名:&#12288;</label>
              <span class="carTit">{{ detail.carname }}</span>
            </div>
            <div class="carBox">
              <label class="carTxt" for="">车牌号:</label>
              <span class="carTit">{{ detail.carnumber }}</span>
            </div>
            <div class="carBox">
              <label class="carTxt" for="">电话:&#12288;</label>
              <span class="carTit">
                {{ detail.carmobile }}
                <img
                  class="callImg"
                  @click="call(detail.carmobile)"
                  src="../assets/img/index/call.png"
                  alt=""
                  v-if="emicreg != ''"
                />
              </span>
            </div>
          </div>
          <div class="informationRight">
            <div class="informationTit">技师信息</div>
            <div class="carBox">
              <label class="carTxt" for="">协作商:</label>
              <span class="carTit">{{ detail.techcompany }}</span>
            </div>
            <div class="carBox">
              <label class="carTxt" for="">车牌号:</label>
              <span class="carTit">{{ detail.techcarnumber }}</span>
            </div>
            <div class="carBox">
              <label class="carTxt" for="">电话:&#12288;</label>
              <span class="carTit">
                {{ detail.techmobile }}
                <img
                  class="callImg"
                  @click="call(detail.techmobile)"
                  src="../assets/img/index/call.png"
                  alt=""
                  v-if="emicreg != ''"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- 任务详情 -->
        <div class="taskDetails">
          <div class="informationTit">
            订单信息
            <span class="applynum" @click="apply(detail.rescueId)">{{
              detail.rescueId
            }}</span>
            <span class="zhuangtai">{{ detail.rescuestatus }}</span>
          </div>

          <div class="taskBox">
            <div class="taskBoxLeft">
              <label class="carTxt" for="">服务类型:</label>
              <span class="taskBoxTit">{{ detail.servicetype }}</span>
            </div>
            <div class="taskBoxRight">
              <label class="carTxt" for="">合作单位:</label>
              <span class="taskBoxTit">{{ detail.fromcompany }}</span>
            </div>
          </div>
          <div class="taskBox">
            <label class="carTxt" for="">任务点:&#12288;</label>
            <span class="taskRenwu">{{ detail.address }}</span>
          </div>
          <div class="taskBox" v-if="detail.destination">
            <label class="carTxt" for="">目的地:&#12288;</label>
            <span class="taskRenwu">{{ detail.destination }}</span>
          </div>
        </div>

        <!-- 申请信息 -->
        <div class="taskDetails">
          <div class="informationTit">申请信息</div>

          <div class="taskBox">
            <label class="carTxt" for="">发起时间:</label>
            <span class="taskRenwu">{{ detail.subtime }}</span>
          </div>
          <div class="taskBox">
            <label class="carTxt" for="">取消位置:</label>
            <span class="taskRenwu">{{ detail.subaddress }}</span>
          </div>
          <div class="taskBox">
            <label class="carTxt" for="">取消原因:</label>
            <span class="taskRenwu">{{ detail.subcontent }}</span>
          </div>
          <div class="taskBox">
            <label class="carTxt" for="">照片:&#12288;&#12288;</label>
            <!-- <img class="photoImg" src="" alt="" /> -->
            <el-image
              style="width: 32px; height: 32px"
              :src="detail.pic"
              :preview-src-list="srcList"
              :initial-index="4"
              fit="cover"
              @click.stop
            />
          </div>
        </div>

        <!-- 操作 -->
        <div class="operation" v-if="detail.candeal">
          <div class="informationTit caozuo">操作</div>
          <div class="cancelType">
            <i class="xing">*</i>
            <label class="carTxt" for="">取消类型:</label>
            <el-radio-group v-model="radio">
              <el-radio :label="1">取消无费</el-radio>
              <el-radio :label="2">取消有费</el-radio>
              <el-radio :label="3">救援失败</el-radio>
            </el-radio-group>
          </div>
          <div class="cancelYuanyin">
            <label class="carTxt" for="">车主电话:</label>
            {{ detail.carmobile }}
            <button
              @click="call(detail.carmobile)"
              class="call"
              v-show="emicreg != ''"
            ></button>
          </div>

          <div class="cancelYuanyin">
            <label class="carTxt" for="">取消原因:</label>
            <el-select v-model="reason" placeholder="请选择原因" @change="ddd">
              <el-option
                v-for="item in reasonlist"
                :key="item.content"
                :label="item.content"
                :value="item.content"
              >
              </el-option>
            </el-select>
          </div>

          <div class="cancelYuanyin" v-if="showother" style="display:flex;">
            <label  class="carTxt">其他原因:</label>
            <el-input
                  type="textarea"
                  class="beiTxt"
                  v-model="otherreason"
                  placeholder="请输入具体的原因"
                ></el-input>
        </div>
        </div>

        <!-- 按钮 -->
        <div class="btn" v-if="detail.candeal">
          <button class="set" @click="subclose">确认取消</button>
          <button class="cancel" @click="gaipai">改派</button>
        </div>
      </div>
    </div>
  </div>
  <ding-gai :isshows="gaipais" :diaodurescueid="detail.rescueId"></ding-gai>
  <part :islook="look" :id="rescueId"></part>
</template>

<script>
import { watch } from "@vue/runtime-core";
import * as api from "../api/orderDetail.js";
import { ElMessage, ElMessageBox } from "element-plus";
import DingGai from "./dingGai.vue";
import part from "./part.vue";
import phonebar from "jssip-emicnet/dist/phonebar";
import * as ola from "../api/olawebsock.js";
const srcList = [];
export default {
  components: { DingGai, part },
  props: ["isapply", "applyid"],
  data() {
    return {
      look: 0,
      rescueId: "",
      applyCancel: this.isapply,
      //   applyCancel: true,
      applyids: this.applyid,
      srcList,
      radio: "",
      gaipais: false,
      reason: "",
      otherreason:'',
      showother:false,
      reasonlist: [],
      detail: {},
      emicreg: "",
    };
  },
  methods: {
    apply(id) {
      this.rescueId = id;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },
    ddd(){
        console.log(this.reason);
    
        let i_s = document.getElementsByClassName("el-input");
        let i_t = document.getElementById("i_textarea");

        console.log(i_s);
        if(this.reason == '其他原因'){
          this.showother = true;
        }else{
          this.showother = false;
        }
    },
    close() {
      this.applyCancel = false;
    },
    gaipai() {
      this.gaipais = true;
    },
    async subclose() {
      let post_type = this.radio;
      let post_reason = this.reason;
      let post_otherreason = this.otherreason;
      let that = this;
      // 判断原因是否为空，为空提醒填写，非空再弹窗
      if (post_reason == "" || post_reason == undefined) {
        // alert('请选择原因');
        that.$message.warning("请选择原因");
        return false;
      }else if(post_reason == "其他原因" && post_otherreason == ''){
        this.$message.warning("请补充其他具体原因");
        return false;
      } else {
        if(post_reason == '其他原因')
        {
          post_reason = post_reason + '：' + post_otherreason;
        }
        ElMessageBox.confirm("是否确定取消", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            if (post_type == 1 || post_type == 3) {
              let res = await api.orderClose(
                that.detail.rescueId,
                post_reason,
                post_type
              );
              if (res.code == 1) {
                // alert('成功');
                that.$message.success("成功");
                that.close();
              } else {
                // alert(res.message)
                that.$message.warning(rs.message);
              }
            } else {
              let res = await api.orderEmpty(that.detail.rescueId, post_reason);
              if (res.code == 1) {
                // alert('成功');
                that.$message.success("成功");
                that.close();
              } else {
                // alert(res.message)
                that.$message.warning(res.message);
              }
            }
          })
          .catch(() => {});
      }
    },
    call(tel) {
      tel = tel.toString();
      let str = this.detail.rescueId.toString();
      
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    // call(mobile) {
    //   let state = phonebar.checkSeatState();
    //   console.log(state);

    //   if (state == 1) {
    //     phonebar.call(mobile);
    //   } else {
    //     this.$message.warning("请先完成当前通话");
    //   }
    // },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    async isapply(newval) {
      console.log("----", newval);
      this.applyCancel = newval;

      let res = await api.rescueTechCancel(this.applyid);
      console.log(res);
      this.detail = res.data;
      this.srcList = [res.data.pic];

      console.log(this.emicreg);
    },
    async radio(newval) {
      console.log("-----", newval);
      this.reason = "";
      this.showother = false;
      this.otherreason = '';
      let res = await api.getCloseReason(this.radio);
      this.reasonlist = res.data;
    },
    getEmic(newval) {
      this.emicreg = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.cancelBox {
  width: 620px;
  position: absolute;
  top: 0px;
  right: 0;

  z-index: 5;
}
.cancelBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.cancelMain {
  width: 620px;
  height: 920px;
  background-color: #fff;
  float: right;
  position: relative;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.cancelMain::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -40px;
  top: 70px;
}
.cancelTit {
  font-size: 20px;
  font-weight: bold;
}
.information {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.informationLeft,
.informationRight {
  width: 274px;
  // height: 150px;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.informationRight {
  margin-left: 24px;
}
.informationTit {
  padding-left: 10px;
  font-size: 16px;
  border-left: 2px solid #3c67f6;
  box-sizing: border-box;
  font-weight: 500;
}
.caozuo {
  margin-left: 10px;
}
.carBox {
  // margin-left: 24px;
  margin-top: 10px;
  display: flex;
}
.carTxt {
  color: rgba(0, 0, 0, 0.45);
  margin-right: 8px;
  font-size: 14px;
}
.carTit {
  width: 100px;
  font-size: 14px;
  display: flex;
}
.callImg {
  margin-left: 10px;
}
.applynum {
  color: #2c68ff;
  font-size: 14px;
  cursor: pointer;
}
.zhuangtai {
  padding: 1px 8px;
  background-color: #d9e4ff;
  border: 1px solid #2c68ff;
  font-size: 12px;
  color: #2c68ff;
  margin-left: 10px;
}
.taskDetails {
  margin-top: 10px;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.operation {
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px 10px 10px 0;
}
.taskBox {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 0;
}
.taskBoxLeft,
.taskBoxRight {
  width: 280px;
  font-size: 14px;
  display: flex;
}
.taskBoxTit {
  width: 180px;
}
.taskRenwu {
  width: 430px;
  font-size: 14px;
}
.photoImg {
  width: 32px;
  height: 32px;
}

.cancelType {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.cancelYuanyin {
  margin-left: 10px;
  margin-top: 10px;
}
i {
  font-style: normal;
}
.xing {
  color: red;
  margin-right: 4px;
}
.cancelType /deep/ .el-radio__label {
  color: #000;
}
.cancelYuanyin /deep/ .el-select .el-input {
  width: 270px;
}
.cancelYuanyin /deep/ .el-textarea {
  width: 270px;
}
.btn {
  margin-top: 46px;
  padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
}
</style>