<template>
  <div>
    <!-- 添加车辆 -->
    <div class="jiacarBox" v-show="gais">
      <img
        @click="close"
        class="cha"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="jiacarmain">
        <div class="jiacarNav">
          <h1 class="jiacarNavTit">修改车辆</h1>
        </div>
      </div>
      <div class="jiaStyle">
        <label class="carStyle" for="">车辆类型:</label>
        <input class="leixing" v-model="lei" type="text" placeholder="请输入车辆类型" disabled />
      </div>
      <div class="jiaStyle1">
        <label class="Commission" for="">提成比:</label>
        <input class="ticheng" v-model="ti" type="text" placeholder="请输入提成比" />
      </div>
      <div class="btn">
        <button class="save" @click="bao">保存</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api/car.js';
export default {
  props: ["isxiu",'xiucartype','xiuticheng'],
  data() {
    return {
      gais: this.isxiu,
      lei:this.xiucartype,
      ti:this.xiuticheng,   
    };
  },
  methods: {
    close() {
      this.gais = false;
    },
    async bao() {
      if(this.ti < 0 || this.ti > 100 || this.ti == '')
      {
        this.$message.warning('请输入正确的提成比例，0~100');
        return;
      }
      let res = await api.updateCarType(this.ti,this.lei);
      if(res.code == 1)
      {
        this.$message.success('成功');
        this.$emit('xiuchange',new Date().getTime());
        this.close();
      }
    },
  },
  watch: {
    isxiu(newval) {

      this.gais = newval;
    },
    xiucartype(newval) {
      this.lei = newval;
    },
    xiuticheng(newval) {
      this.ti = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.jiacarBox {
  width: 600px;
  height: 920px;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 55;
  background-color: #fff;
  box-shadow: 0px -5px 10px #e0e0e0;
}

.cha {
  float: left;
  width: 103px;
  height: 103px;
  position: absolute;
  top: 36px;
  left: -70px;
}
.jiacarNav {
  height: 80px;
  width: 600px;
}
.jiacarNavTit {
  font-size: 20px;
  line-height: 80px;
  margin-left: 30px;
  font-weight: 400;
  color: #2a3346;
}
.jiaStyle {
  margin-top: 24px;
  margin-left: 44px;
}
.jiaStyle1 {
  margin-top: 36px;
  margin-left: 58px;
}
.carStyle,
.Commission {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.leixing,
.ticheng {
  width: 240px;
  height: 32px;
  border-radius: 4px;
  margin-left: 14px;
  padding-left: 12px;
  border: 1px solid #dcdfe6;
  outline: none;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
input:hover {
  border: 1px solid #c0c4cc;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}

.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.btn {
  margin-left: 117px;
  margin-top: 40px;
  float: left;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}

</style>