<template>
  <div>
    <div class="box" v-show="cans">
      <div id="main">
        <i class="tiao"></i>
        <h1>修改密码</h1>
        <h3 v-if="tips == 1">您有90天未修改密码，未了账户安全请及时更新密码</h3>
        <div class="mold">
          <label class="new" for="">新密码</label>
           <input
            type="password"
            name=""
            id=""
            v-model="password"
            placeholder="请输入要改的新密码"
          />
        </div>
        <div class="mold">
          <label for="">确认密码</label>
           <input
            type="password"
            name=""
            id=""
            v-model="passwordrepeat"
            placeholder="请确认密码"
          />
        </div>
        <div class="btn">
          <button class="set" @click="update">修改密码</button>
          <button @click="cel" class="cancel" v-if="tips != 1">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import * as api from "../api/index.js";
import Cookies from 'js-cookie';

// do not use same name with ref
const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});
const radio1 = ref("1");
const radio2 = ref("1");
const radio3 = ref("1");
export default {
  props:["isame",'tips'],
  data() {
    return {
      radio1,
      radio2,
      radio3,
      form,
      cans: this.isame,
      password:'',
      passwordrepeat:'',
    };
  },
  methods: {
    cel() {
      this.cans = false;
      Object.assign(this.$data, this.$options.data());
      // this.can = !this.can;
    },
    async update()
    {
        if(this.password == '')
        {
          this.$message.warning('请输入要修改的密码');
          return false;
        }

        if(this.passwordrepeat == '')
        {
          this.$message.warning('请重复要修改的密码');
          return false;
        }

        if(this.password != this.passwordrepeat)
        {
          this.$message.warning('二者不一致');
          return false;
        }

        let res = await api.updatepassword(this.password,this.passwordrepeat);
        //console.log(res);

        if(res.code == 1){
          this.$message.success('成功');
          // Cookies.set('ntwl-token', res.data.token);
          // Cookies.set('ntwl-authkey', res.data.token);
          Cookies.set('ntwl-token', res.data.token,{domain: 'nituowola.com'});
          Cookies.set('ntwl-authkey', res.data.token,{domain: 'nituowola.com'});
          this.cel();
        }else{
          this.$message.warning(res.message);
        }
    }
  },
  watch:{
    isame(newval){
      console.log('---',newval);
      this.cans = newval
    }
  }
};
</script>

<style lang="less" scoped>
.box {
 float: left;
 width: 1920px;
height: 940px;
 background: #000000; background: rgba(0, 0, 0, 0.4); border-radius: 0px;
 position: relative;
 z-index: 50;
 position: absolute;
top: 0;
 left: 0;
}
#main {
  width: 520px;
  height: 260px;
  opacity: 1;
  //   background-color: pink;
  background: rgba(255, 255, 255, 1);
  margin: 290px auto;
  position: absolute;
  top: 0;
  left: 700px;
}
h1 {
  width: 490px;
  height: 60px;
  margin-left: 30px;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px 2px 0px 0px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 60px;
  color: #2a3346;
  opacity: 1;
}
h3{
  margin-bottom: 10px;
  margin-left: 30px;
  color: red;
}
.tiao {
  float: left;
  width: 3px;
  height: 15px;
  margin-top: 23px;
  margin-left: 19px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.mold {
  width: 400px;
  // height: 19px;
  margin-bottom: 25px;
  margin-left: 30px;
  //   background-color: skyblue;
}
label {
  width: 90px;
  height: 19px;
  font-size: 14px;
  margin-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.new{
    margin-right: 34px;
}
input {
  width: 260px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  outline: none;
}
input:hover{
  border: 1px solid#c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 24px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
