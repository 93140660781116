'use strict';
var cur_call = null;
var confMan = null;
var vertoPhone = "";
var verto;
var ringing = false;
var chatting_with = false;
var clearAutoHangup = 0;
var clearAutoReply = 0;
/*$( ".selector" ).pagecontainer({ "theme": "a" });*/

function clearConfMan() {
    if (confMan) {
        confMan.destroy();
        confMan = null;
    }
    chatting_with = null;
}

var callbacks = {
    onMessage: function(verto, dialog, msg, data) {
    	$("#webRtc_status").text("webRTC已连接").css("color","#098029");
    },
    onDialogState: function(d) {
        cur_call = d;
		if (d.state == $.verto.enum.state.ringing) {
		    ringing = true;
		} else {
		    ringing = false;
		}
		//console.log(d);
        switch (d.state) {
       	case $.verto.enum.state.ringing:
			vertoPhone = (d.cidString()).substring(0,12);
			if(vertoPhone.substring(0, 2) == "01")
			{
				vertoPhone = (d.cidString()).substring(1,12)
			}
			//clearAutoReply = setTimeout(vertoAnswer(),parseInt(1)*1000);
			break;
       	case $.verto.enum.state.trying:
    	case $.verto.enum.state.early:
    	case $.verto.enum.state.active:
	        clearTimeout(clearAutoHangup);
			clearTimeout(clearAutoReply);
            break;
    	case $.verto.enum.state.hangup:
    	case $.verto.enum.state.destroy:
        	clearConfMan();
        	cur_call = null;
        	break;
       	default:
       		break;
       }
    },
    onWSLogin: function(v, success) {
    	//$("#incomingCall").hide();
    	if (success) {
        	// $("#vertoStatus").text("Connected").css("color","#098029");
			// $("#vertoConn").hide();
			// $("#DisConnect").show();
			// $("#ola_extn").attr("disabled",true);
			// $("#agentPwd").attr("disabled",true);
			// $("#vertoSerIp").attr("disabled",true);
			// $("#serPort").attr("disabled",true);
        } else {
        	//$("#vertoStatus").text("Disconnected").css("color","#383F42");
        }
    },
    onWSClose: function(v, success) {
    	// $("#incomingCall").hide();
    	// $("#vertoStatus").text("Disconnected").css("color","#383F42");
		// $("#ola_extn").attr("disabled",false);
		// $("#agentPwd").attr("disabled",false);
		// $("#vertoSerIp").attr("disabled",false);
		// $("#serPort").attr("disabled",false);
    },
    onEvent: function(v, e) {
    	//$("#incomingCall").hide();
        console.debug("GOT EVENT", e);
    },
};

function vertoLogin(un,pwd) {
    var ola_extn = un;
    var agentPwd = pwd;
    //var serIp = 'internal.ttf-cti.com';
	var serIp = '113.31.113.243';
	//var serIp = 'tianrun.xswitch.cn';
    verto = new $.verto({
	        login: ola_extn + "@" + serIp,
	        passwd: agentPwd,
	        socketUrl: "wss://" + serIp + ":10085/webrtc",
	        tag: "webcam",
	        ringFile: "https://s.nituowola.com/sounds/bell_ring2.wav",
	},callbacks);
}


function doCall(dest_phone) {
    if(dest_phone != ""){
            $("#reDest_phone").val(dest_phone);
	    cur_call = verto.newCall({
	        destination_number: dest_phone,
	        caller_id_name: $("#ola_extn").val(),
	        caller_id_number: $("#ola_extn").val()
	    });
    }
}
function vertoLogout(){
     verto.logout();
}

function vertoAnswer(){
	if(cur_call != null){
		cur_call.answer({
		});
	}
}

function vertoDecline(){
	if(cur_call != null){
	   cur_call.hangup({});
	}
}

function dtmf(dtmf)
{
	//console.log(dtmf);
	if(cur_call != null && cur_call.state.name == "active"){
			cur_call.dtmf(dtmf);
	}

}

export {vertoLogin,vertoAnswer,vertoLogout,dtmf};
