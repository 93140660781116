<template>
<div @click.stop>
  <div class="boxr" v-show="apps">
    <img @click="close" src="../assets/img/ding/icon-close.png" alt=""  style="cursor: pointer;"/>
    <div class="box-main">
      <div class="main-nav">
        <h1>个人中心</h1>
      </div>
      <div class="name">
        <label class="label1" for="">姓名</label>
        <input class="input1" type="text" placeholder="孙先生" v-model="name" />
      </div>
      <div class="name">
        <label class="label1" for="">帐号</label>
        <input class="input1" type="text" placeholder="13901234567" v-model="username" readonly/>
      </div>
      <div class="name">
        <label class="label1" for="">密码</label>
        <input class="input1" type="password" placeholder="*******" />
        <span @click="amend">修改</span>
      </div>
      <div class="name">
        <label class="label2" for="">联系电话</label>
        <input class="input1" type="text" placeholder="0574-52269164" v-model="tel"/>
      </div>
      <div class="name" v-if="wx_name !=''">
        <label class="label2" for="">微信绑定</label>
        <input
          class="input1"
          type="text"
          v-model="wx_name"
          readonly
        />
      </div>
      <div class="name" v-if="wx_name ==''">
        <label class="label2" for="">微信绑定</label>
        <button class="bind" @click="WeChat">绑定微信帐号</button>
      </div>
      <div class="name" v-if="openid_kj !='' && iskj">
        <label class="label2" for="">快捷绑定</label>
        <input
          class="input1"
          type="text"
          v-model="openid_kj"
          readonly
        />
      </div>
      <div class="name" v-if="openid_kj =='' && iskj">
        <label class="label2" for="">快捷绑定</label>
        <button class="bind" @click="WeChatKj">绑定微信帐号</button>
      </div>
      <div class="btn">
        <button class="save" @click="submit">保存</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>

    <scan :iscode="yare" @usersx="sx"></scan>
    <scan-kj :iscodekj="yarekj" @usersx="sxkj"></scan-kj>
    
  </div>
      <amend :isame="ame" :tips="0"></amend>
</div>
</template>

<script>
import amend from './amend.vue';
import Scan from '../components/scan.vue';
import ScanKj from '../components/scanKj.vue';
import * as api from "../api/index";
export default {
  props:["isper"],
  components: { amend,Scan,ScanKj },
  data() {
    return {
      apps: this.isper,
      ame:0,
      name:'',
      username:'',
      tel:'',
      wx_name:'',
      openid_kj:'',
      yare:0,
      yarekj:0,
      iskj:false,
    };
  },
  methods: {
    close() {
      this.apps = false;
      // this.app = !this.app;
    },
    amend() {
      this.ame++;
      if(this.ame == 3){
        this.ame = 1
      }
      // this.ame = !this.ame;
    },
    sx()
    {
      this.getuserinfo();
    },
    sxkj()
    {
      this.getuserinfo();
    },
    async getuserinfo()
    {
      let res = await api.userinfo();
      console.log(res);
      this.name = res.data.userinfo.nickname;
      this.username = res.data.userinfo.username;
      this.tel = res.data.userinfo.tel;
      if(res.data.userinfo.wx_name != ''){
        this.wx_name = res.data.userinfo.openid + "（已绑定）";
      }else{
        this.wx_name = res.data.userinfo.wx_name;
      }

      if(res.data.userinfo.cid == 100000055 || res.data.userinfo.cid == 1)
      {
        this.iskj = true;
      }else
      {
        this.iskj = false;
      }

      if(res.data.userinfo.openid_kj != ''){
        this.openid_kj = res.data.userinfo.openid_kj + "（已绑定）";
      }else{
        this.openid_kj = res.data.userinfo.openid_kj;
      }

      
    },
    async submit(){
        let res = await api.updateuserinfo(this.name,this.tel);
        if(res.code == 1)
        {
          this.$message.success('成功');
          this.getuserinfo();
        }else{
          this.$message.warning(res.message);
        }
    },
     WeChat() {
        this.yare++;
        if(this.yare == 3) {
            this.yare = 1
        }
    },
    WeChatKj() {
        this.yarekj++;
        if(this.yarekj == 3) {
            this.yarekj = 1
        }
    }
  },
  watch:{
    isper(newval){
      console.log('---',newval);
      this.apps = newval
      this.getuserinfo();
    }
  }
};
</script>

<style lang="less" scoped>
.boxr {
  width: 540px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  //   background-color: #fff;
  //   background-color: pink;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 500px;
  height: 940px;
  background-color: #fff;
  //   background: orange;
  margin-left: 40px;
  //   margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 500px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.name {
  width: 420px;
  opacity: 1;
  margin-left: 34px;
  margin-top: 24px;
  //   background-color: pink;
}
.label1 {
  width: 28px;
  height: 19px;
  font-size: 14px;
  margin-right: 48px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}

.label2 {
  width: 28px;
  height: 19px;
  font-size: 14px;
  margin-right: 48px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  color: #2a3346;
  opacity: 1;

  margin-right: 20px;
}
.input1 {
  width: 260px;
  height: 32px;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  padding-left: 12px;
  border: 1px solid#dcdfe6;
  opacity: 1;
  border-radius: 4px;
}
.input1:hover{
  border: 1px solid #c0c4cc;
}
.bind {
  width: 130px;
  height: 32px;
  background: #dadfe6;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
}
.bind:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}


.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;

  opacity: 1;
  border-radius: 2px;
}
.btn {
  margin-left: 106px;
  margin-top: 32px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
span {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
  margin-left: 14px;
  cursor: pointer;
}
</style>
