<template>
<div @click.stop>
  <div class="boxr" v-show="arrs">
    <img
      class="cha"
      @click="close"
      src="../assets/img/ding/icon-close.png"
      alt=""
    />
    <div class="box-main">
      <div class="main-nav">
        <h1>帐号认证</h1>
      </div>
      <div class="name1">
        <label class="label1" for="">公司名称</label>
        <input
          class="input1"
          type="text"
          placeholder="公司名称"
          v-model="name"
        />
      </div>
      <div class="name">
        <label class="label1" for="">法人姓名</label>
        <input
          class="input1"
          type="text"
          placeholder="输入法人姓名"
          v-model="idname"
        />
      </div>
      <div class="name">
        <label class="label2" for="">法人身份证</label>
        <input
          class="input1"
          type="text"
          placeholder="输入法人身份证"
          v-model="license_num"
        />
      </div>
      <div class="name">
        <label class="label3" for="">开户行</label>
        <input
          class="input1"
          type="text"
          placeholder="输入开户行"
          v-model="open_name"
        />
      </div>
      <div class="name">
        <label class="label4" for="">开户账号</label>
        <input
          class="input1"
          type="text"
          placeholder="开户银行账号"
          v-model="bank_num"
        />
      </div>
      <div class="photo-box">
        <label class="label5" for="">认证图片</label>
        <div class="upBox">
          <div class="tuBox">
            <div class="beiTu">
              <img class="tuImg" :src="imgSrc[0].imgSrc" alt="" />
            </div>
            <input class="tu" type="file" @change="change" data-index="0" />
            <span class="photo-span">身份证(头像)</span>
          </div>

          <div class="tuBox">
            <div class="beiTu">
              <img class="tuImg" :src="imgSrc[1].imgSrc" alt="" />
            </div>
            <input class="tu" type="file" @change="change" data-index="1" />
            <span class="photo-span">身份证(国徽)</span>
          </div>

          <div class="tuBox">
            <div class="beiTu">
              <img class="tuImg" :src="imgSrc[2].imgSrc" alt="" />
            </div>
            <input class="tu" type="file" @change="change" data-index="2" />
            <span class="photo-span">营业执照</span>
          </div>

          <div class="tuBox">
            <div class="beiTu">
              <img class="tuImg" :src="imgSrc[3].imgSrc" alt="" />
            </div>
            <input class="tu" type="file" @change="change" data-index="3" />
            <span class="photo-span">开户证明</span>
          </div>
        </div>
      </div>
      <div class="btn">
        <button class="save" v-if="isauth == 0" @click="save">认证</button>
        <button class="save" v-if="isauth != 3 && isauth > 0" @click="save">重新认证</button>
        <button class="save" v-if="isauth == 3" disbalbe >认证成功</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from "vue";
import * as api from "../api/index";
import {compress} from "../api/pic";
import axios from "axios";
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const disabled = ref(false);
export default {
  props: ["isapp"],
  data() {
    return {
      dialogImageUrl,
      dialogVisible,
      disabled,
      arrs: this.isapp,
      name: "",
      idname:"",
      lisence_num: "",
      open_name: "",
      bank_num: "",
      imgSrc: [
        { imgSrc: "" },
        { imgSrc: "" },
        { imgSrc: "" },
        { imgSrc: "" },
        { imgSrc: "" },
      ],
      isauth:'',
      auth:[],
    };
  },
  methods: {
    close() {
      this.arrs = false;
      // this.arr = !this.arr;
    },
    async change(e) {
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      console.log(file);
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
      formData.append("pic", res);
      axios.post("https://dispatch-test.nituowola.com/site/upload-auth-pic", formData).then(res => {
                if(res.data.code ==1)
                {
                  _this.imgSrc[index].imgSrc = "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" + res.data.pic;
                }else{
                  _this.$message.warning('图片上传失败')
                }
              })
          });
    },
    async getinfo()
    {
        let res = await api.getAuthInfo();
        if(res.code == 1)
        {
          this.isauth = res.data.is_auth;
          let auth = res.data.auth;
          console.log(auth);
          if(this.isauth > 0)
          {
            this.name = auth.sp_name;
            this.idname = auth.name;
            this.license_num = auth.idcard;
            this.open_name = auth.open_name;
            this.bank_num = auth.bank_num;
            if(auth.idcarda != ''){
              this.imgSrc[0].imgSrc = "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" + auth.idcarda;
            }
            if(auth.idcardb != ''){
              this.imgSrc[1].imgSrc = "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +auth.idcardb;
            }
            if(auth.lisence_pic != ''){
               this.imgSrc[2].imgSrc = "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +auth.license_pic;
            }
            if(auth.bank_pic != ''){
               this.imgSrc[3].imgSrc = "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +auth.bank_pic;
            }
            
          }
        }
    },
    compressAvatard(file, kb, callback) {
                let _this = this;
                let raw = file.raw? file.raw: file;
                compress(raw, function (val) {
                // val是Blob类型，转换为file类型
                let newfile = new window.File([val], file.name, {type: raw.type});
                newfile.uid = file.uid;
                // 压缩率过低则停止压缩, 防止死循环
                if ((newfile.size / 1024) > kb && (newfile.size / file.size < 0.9 ) ) {
                    console.log("图片过大:" + newfile.size + ',已压缩');
                    _this.compressAvatard(newfile, kb, callback);
                } else {
                    callback(newfile);
                    console.log("压缩后大小:" + newfile.size);
                }
                });
        
    },
    async save()
    {
      if(this.name == ''){
          this.$message.waring('请填写公司名称');
          return;
      }
      if(this.idname == ''){
          this.$message.waring('请填写法人姓名');
          return;
      }
      if(this.license_num == ''){
          this.$message.waring('请填写法人身份证号');
          return;
      }
      if(this.open_name == '')
      {
          this.$message.waring('请填写开户银行');
          return;
      }
      if(this.bank_num == '')
      {
          this.$message.waring('请填写开户银行账号');
          return;
      }
      if(this.imgSrc[0].imgSrc =='' || this.imgSrc[1].imgSrc =='' || this.imgSrc[2].imgSrc =='' || this.imgSrc[3].imgSrc =='' )
      {
          this.$message.waring('请按要求上传照片');
          return;
      }

      let res = await api.uploadauth(this.name,this.idname,this.license_num,this.open_name,this.bank_num,this.imgSrc[0].imgSrc,this.imgSrc[1].imgSrc,this.imgSrc[2].imgSrc,this.imgSrc[3].imgSrc);


      if(res.code == 1)
      {
        this.$message.success('上传成功，等待审核');
        this.getinfo();
      }else{
        this.$message.waring(res.message);
      }
    }
  },
  watch: {
    isapp(newval) {
      console.log("---", newval);
      this.arrs = newval;
      this.getinfo();
      
    },
  },
};
</script>

<style lang="less" scoped>
.boxr {
  width: 540px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
  //   background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 500px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  margin-top: 0px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 500px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.name {
  width: 400px;
  opacity: 1;
  margin-left: 34px;
  margin-top: 24px;
  //   background-color: pink;
}
.name1{
  width: 400px;
  opacity: 1;
  margin-left: 26px;
  margin-top: 24px;
}
.label1,
.label2,
.label3,
.label4 {
  //width: 28px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}
.label1 {
  margin-right: 34px;
}
.label2 {
  margin-right: 20px;
}
.label3 {
  margin-right: 48px;
}
.label4 {
  margin-right: 34px;
}
.label5 {
  float: left;
  width: 100px;
  height: 19px;
  font-size: 14px;
  margin-top: 9px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 19px;
}
.input1 {
  width: 260px;
  height: 32px;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  padding-left: 12px;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
}
.input1:hover {
  border: 1px solid #c0c4cc;
}
.bind {
  width: 130px;
  height: 32px;
  background: #dadfe6;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 4px;
}
.bind:hover {
  border: 1px solid #c0c4cc;
}

input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}

.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.btn {
  margin-left: 130px;
  margin-top: 40px;
  float: left;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
// .photo {
//   float: left;
//   width: 100px;
//   height: 160px;
//   margin-right: 14px;
//   padding: 2px 4px 0 4px;
//   box-sizing: border-box;
// }
.img1 {
  width: 92px;
  height: 126px;
  box-sizing: border-box;
}
.photo-span {
  position: absolute;
  top: 130px;
  left: 0;
  width: 100px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
.photo-box {
  width: 440px;
  display: flex;
  // height: 160px;
  margin-left: 33px;
  padding-top: 14px;
}
.take {
  display: flex;
  position: relative;
}
.take /deep/ .el-upload--picture-card {
  width: 100px;
  height: 126px;
}
.take /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 126px;
  background-size: 100% 100%;
  margin-bottom: 30px;
}

.tuBox {
  width: 100px;
  height: 160px;
  float: left;
  position: relative;
  margin-right: 18px;
}
.tu {
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.tuImg {
  width: 100px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
img[src=""],
img:not([src]) {
  opacity: 0;
}
.beiTu {
  width: 100px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/cd/jia.png");
  background-size: 100% 100%;
}
</style>
