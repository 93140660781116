<template>
  <div>
    <!-- 订单匹配 -->
    <div class="box2" v-if="detail && matchs">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main" :class="{ boxmain2: mapyin == true }">
        <div class="main-nav" :class="{ jiaMain: mapyin == true }">
          <h1>订单匹配</h1>

          <div>
            <img class="shua" src="../assets/img/ding/shua.png" alt="" />
            <i class="xin" @click="refresh(detail.订单号)">刷新</i>
          </div>
        </div>

        <div class="pimain">
          <div class="pileft">
            <div class="order">
              <span class="tiao"></span>
              订单号:{{ detail.订单号 }}
              <span class="take">{{ detail.服务状态 }}</span>
              <span class="take1" v-if="detail.rescue_style == 1">即时单</span>
              <span class="take2" v-if="detail.rescue_style == 2">预约单</span>
              <span class="take2" v-if="detail.rescue_style == 3">顺路单</span>
            </div>
            <div class="order-main">
              <div>
                <div class="hezuo">
                  <div class="p1">
                    <span class="title">合作单位&#12288;&nbsp;</span>
                    <span class="title-main">{{ detail.合作单位 }}</span>
                  </div>
                  <div class="xiangmu">
                    <span class="title">服务项目</span>
                    <i class="title-main yanse">{{ detail.服务项目 }}</i>
                  </div>
                </div>

                <div class="hezuo">
                  <div class="p1">
                    <span class="title">创建日期&#12288;&nbsp;</span>
                    <span class="title-main">{{ detail.创建时间 }}</span>
                  </div>
                  <!-- <div class="xiangmu" v-if="detail.appointment_time">
                <span class="title">预约时间</span>
                <i class="title-main yanse">{{ detail.appointment_time }}</i>
              </div> -->
                  <div class="xiangmu" v-if="detail.rescue_time">
                    <span class="title">救援时间</span>
                    <i class="title-main yanse">{{ detail.rescue_time }}</i>
                  </div>
                </div>
              </div>
              <div>
                <div class="diBox">
                  <label class="remTit">任务点&#12288;&#12288;&nbsp;</label>
                  <div class="diTxt">
                    {{ detail.事故地点 }}
                  </div>
                </div>

                <div class="diBox" v-if="detail.目的地">
                  <span class="remTit">目的地&#12288;&#12288;&nbsp;</span>
                  <i class="diTxt">
                    {{ detail.目的地 }}
                  </i>
                </div>
              </div>

              <div class="receiving">
                <span class="title">协作商/技师</span>

                <div class="title-main" v-if="detail.接单人员">
                  {{ detail.接单公司 }}{{ detail.xzs }}
                  <button
                    @click="call(detail.xzs, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.xzs != ''"
                  ></button>
                  <span v-if="detail.接单人员">/</span>
                  {{ detail.接单人员 }}
                  <button
                    @click="call(detail.takemobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.takemobile != ''"
                  ></button>
                </div>
              </div>
              <!-- <div class="receiving">
                <span class="title">技师&#12288;&#12288;&#12288;</span>
                <div class="title-main" v-if="detail.接单人员">
                  {{ detail.接单人员 }}
                  <button
                    @click="call(detail.takemobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.takemobile != ''"
                  ></button>
                </div>
              </div> -->
              <!-- <div class="receiving">
            <span class="title">接单人&#12288;</span>
            <div class="title-main" v-if="detail.接单人员">
              {{ detail.接单公司 }}/{{ detail.接单人员 }}
              <button
                @click="call(detail.takemobile, detail.订单号)"
                class="call"
                v-show="emicreg != '' && detail.takemobile != ''"
              ></button>
            </div>
          </div> -->

              <!-- <div class="remBox" v-if="detail.订单备注">
                <span class="remTit">订单备注&#12288;&nbsp;</span>
                <span class="remTxt" v-html="detail.订单备注"></span>
              </div> -->

              <!-- <div class="remBox">
                <span class="remTit" v-if="detail.spRemark">合作商备注</span>
                <span class="remTxt" v-html="detail.spRemark"></span>
              </div> -->
            </div>
          </div>
          <div class="piright">
            <div class="owner">
              <span class="tiao1"></span>
              车主信息
            </div>
            <div class="owner-main">
              <!-- 车主信息/车牌号/手机号/编号 -->
              <div>
                <div class="hezuo">
                  <div class="p2">
                    <label class="title">车主信息</label>
                    <span class="title-main"
                      >{{ detail.车主姓名 }}/{{ detail.手机号
                      }}<button
                        @click="call(detail.手机号, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </div>
                  <div class="xiangmu">
                    <label class="title">车牌车架号</label>
                    <span class="title-main2"
                      >{{ detail.车牌号 }}/{{ detail.车架号 }}
                    </span>
                  </div>
                </div>

                <div class="hezuo">
                  <div class="p2">
                    <label class="title">车型&#12288;&#12288;</label>
                    <span class="title-main">{{ detail.cartype }} </span>
                  </div>

                  <div class="xiangmu">
                    <label class="title" v-if="detail.编号"
                      >编号&#12288;&#12288;&#12288;</label
                    >

                    <span class="title-main2" v-if="detail.编号" ref="jiahao">{{
                      detail.编号
                    }}</span>
                  </div>
                </div>
                <!-- <div class="hezuo">
                  <div class="p2">
                    <label class="title">车架号&#12288;</label>
                    <span class="title-main">{{ detail.车架号 }} </span>
                  </div>
                </div> -->
              </div>

              <!-- 发车接车人 -->
              <div>
                <!-- 下单人 -->
                <!-- v-if="detail.givename" -->
                <div class="hezuo">
                  <p class="p2" v-if="detail.givename">
                    <span class="title">下单人&#12288;</span>
                    <span class="title-main"
                      >{{ detail.givename }}/{{ detail.givemobile
                      }}<button
                        @click="call(detail.givemobile, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </p>
                  <!-- v-if="detail.name2" -->
                  <div class="xiangmu" v-if="detail.name2">
                    <span class="title">接车人&#12288;</span>
                    <span class="title-main"
                      >{{ detail.name2 }}/{{ detail.tel2
                      }}<button
                        @click="call(detail.tel2, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </div>
                </div>

                <!-- 发车人 -->
                <!-- v-if="detail.name1" -->
                <div class="hezuo facar">
                  <p class="p2" v-if="detail.name1">
                    <span class="title">发车人&#12288;</span>
                    <span class="title-main"
                      >{{ detail.name1 }}/{{ detail.tel1
                      }}<button
                        @click="call(detail.tel1, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </p>
                  <div class="xiangmu" v-if="detail.saleman">
                    <span class="title">业务员&#12288;</span>
                    <span class="title-main"
                      >{{ detail.saleman
                      }}<button
                        @click="call(detail.salemanmobile, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </div>
                </div>

                <!-- 接车人 -->
                <!-- <div class="hezuo" v-if="detail.name2">
                  <p class="p2">
                    <span class="title">接车人&#12288;</span>
                    <span class="title-main"
                      >{{ detail.name2 }}/{{ detail.tel2
                      }}<button
                        @click="call(detail.tel2, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </p>
                </div> -->

                <!-- 业务员 -->
                <!-- <div class="hezuo" v-if="detail.saleman">
                  <p class="p2">
                    <span class="title">业务员&#12288;</span>
                    <span class="title-main"
                      >{{ detail.saleman
                      }}<button
                        @click="call(detail.salemanmobile, detail.订单号)"
                        class="call"
                        v-show="emicreg != ''"
                      ></button
                    ></span>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 订单备注 -->
        <div class="remBox" v-if="detail.订单备注">
          <span class="remTit">订单备注&#12288;&nbsp;</span>
          <span class="remTxt" v-html="detail.订单备注"></span>
        </div>
        <!-- 合作商备注 -->
        <div class="remBox">
          <span class="remTit" v-if="detail.spRemark">合作商备注</span>
          <span class="remTxt" v-html="detail.spRemark"></span>
        </div>

        <div class="btn" v-if="btnyin">
          <button
            class="recycle"
            v-if="detail.canRecycle"
            :plain="true"
            @click="recycle(detail.订单号)"
          >
            回收
          </button>
          <button
            class="cancel"
            v-if="detail.可编辑"
            :plain="true"
            @click="edit(detail.订单号)"
          >
            修改
          </button>

          <button class="cancel" @click="dingbei(detail.订单号)">备注</button>

          <!-- <button class="cancel" v-if="detail.canCancel" @click="lish">
            取消
          </button> -->
        </div>

        <div class="paiList" :class="{ jiapai: mapyin == true }">
          <table border="0" cellspacing="0" :class="{ paiNav: mapyin == true }">
            <thead>
              <!-- <th class="jiedan">合作单位</th> -->
              <th class="zhongduan">救援时间</th>
              <th class="jishixinxi">合作商简称/技师</th>
              <th class="dingdanxinxi">订单信息</th>
              <th class="cartype">任务点</th>
              <th class="information">目的地</th>
              <th class="juli">距离</th>
              <th class="current">当前</th>
              <th class="operation">操作</th>
            </thead>
            <tbody v-if="mapyin == true">
              <tr v-for="(data, index) in driverListMap" :key="index">
                <!-- <td class="jiedanTit">
                  {{ data.name }}
                </td> -->
                <td class="jzhongduanTit">
                  {{ data.rescueTime }}
                </td>
                <td class="jishixinxitit informationTit">
                  <img
                    class="xianImg"
                    src="../assets/img/cd/fu.png"
                    alt=""
                    v-if="data.status == 3"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/xian.png"
                    alt=""
                    v-if="data.status == 2"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/ting.png"
                    alt=""
                    v-if="data.status == 1"
                    :class="{ taiImg: mapyin == true }"
                  />
                  {{data.techcompany}}  {{ data.techname }}
                  <el-popover placement="right" :width="800" trigger="hover">
                    <template #reference>
                      <img
                        class="yaImg"
                        src="../assets/img/index/ya.png"
                        alt=""
                        v-if="data.techorder.length > 0"
                      />
                    </template>
                    <el-table :data="data.techorder">
                      <el-table-column
                        width="200"
                        property="service"
                        label="服务类型"
                      />
                      <el-table-column
                        width="300"
                        property="address"
                        label="任务点"
                      />
                      <el-table-column
                        width="300"
                        property="destination"
                        label="目的地"
                      />
                    </el-table>
                  </el-popover>
                </td>
                <td class="dingdanxinxitit">
                  <span
                        class="zhuang"
                        :style="{ background: data.color }"
                        >{{ data.statusType }}</span>
                </td>

                <td class="jzhongduanTit newrenwu">
                  <img
                    class="xianImg"
                    src="../assets/img/cd/shun.png"
                    alt=""
                    v-if="data.type == 1"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/hui.png"
                    alt=""
                    v-if="data.type != 1"
                  />
                  {{ data.address }}
                </td>

                <td class="jzhongduanTit">
                  {{ data.destination }}
                </td>

                <!-- <td class="jzhongduanTit">
                  {{ data.type==1?'顺路':'回程' }}
                </td>       -->
                <td class="juliTit">{{ data.distance }}公里</td>
                <td class="juliTit">{{data.nowlocation}}</td>
                <td class="operationTit">
                  <span
                    class="operBtn"
                    @click="zhipai(data.tech)"
                    v-show="
                      this.detail.接单人员 != '' &&
                      data.tech != '' &&
                      this.detail.take_user != data.tech
                    "
                    >改派</span
                  >
                  <span
                    class="operBtn"
                    @click="zhipai(data.tech)"
                    v-show="this.detail.接单人员 == '' && data.tech != ''"
                    >指派</span
                  >
                  <span
                    class="operBtn"
                    v-show="
                      this.detail.take_user == data.tech && data.tech != ''
                    "
                    >当前</span
                  >

                  <div class="gaiprice" v-show="index == gaiindex">
                    <div class="gaipricetit">
                      流转价格
                      <div class="btns">
                        <button
                          class="jishi"
                          :class="jishi == true ? 'color' : ''"
                          @click="typejishi"
                        >
                          即时
                        </button>
                        <button
                          class="yuyue"
                          :class="yuyue == true ? 'color' : ''"
                          @click="typeyuyue"
                        >
                          预约
                        </button>
                        <button
                          class="shunlu"
                          :class="shunlu == true ? 'color' : ''"
                          @click="typeshunlu"
                        >
                          顺路
                        </button>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel" for="">价格设置</label>
                      <el-radio
                        v-model="chargeType"
                        label="1"
                        size="large"
                        @change="funGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="chargeType"
                        label="2"
                        size="large"
                        @change="funGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="a"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="b"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="c"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel">取消</button>
                      <button class="deter" @click="gaiseal">确定</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <rescue-edit
    :isshowedit="showedit"
    :rescueIdx="xiugaiidx"
    @hide="hide"
  ></rescue-edit>

  <remarks
    :isgaiBei="gaibei"
    :dingId="resdingIds"
    @remarksx="beizhuMessage"
  ></remarks>

  <canael :iscar="car" :rescueIds="guanbiids"></canael>
</template>

<script>
import * as api from "../api/orderDetail";
import rescueEdit from "../components/rescueEdit.vue";
import canael from "../components/canael.vue";
import remarks from "../components/remarks.vue";
import phonebar from "jssip-emicnet/dist/phonebar";
import { id } from "element-plus/lib/locale";
import * as ola from "../api/olawebsock.js";
import * as vt from "../api/verto.js";

export default {
  props: ["matchrescueid", "matches"],
  components: { rescueEdit, canael, remarks },
  data() {
    return {
      mapyin: true,
      yaIndex: -1,
      hao: -1,
      pai: 1,
      carNum: "",
      matchs: this.matches,
      detail: [],
      center: [121.483874, 29.861542],
      map: "",
      driverList: [],
      driverList1: [],
      driverListMap: [],
      driverListMap1: [],
      showedit: false,
      car: 0,
      gaibei: 0,
      rescueId: this.id,
      guanbiids: "",
      xiugaiidx: "",
      resdingIds: "",
      emicreg: this.$store.state.emic,
      img: require("../assets/img/cd/list.png"),
      img1: require("../assets/img/cd/wei.png"),
      imgs: require("../assets/img/cd/list1.png"),
      imgs1: require("../assets/img/cd/wei1.png"),
      checked1: true,
      cartype: 1,
      planline: [],
      plankm: "",
      plantime: "",
      btnyin: true,
      //   btnyin: false,
      newbtnyin: true,

      zhan: false,
      gaiindex: -1,
      gaiindex2: -1,
      gaiindex3: false,
      chargeType: "1",
      showYiKou: false,
      a: "",
      aa: "",
      b: "",
      bb: "",
      c: "",
      cc: "",
      d: "",
      flag: true,

      jishi: true,
      yuyue: false,
      shunlu: false,
      discount1: 1,
      discount2: 1,
      discount3: 1,
      gold: "https://dispatch-test.nituowola.com/img/amap/gold.png",
      silver: "https://dispatch-test.nituowola.com/img/amap/silver.png",
      bronze: "https://dispatch-test.nituowola.com/img/amap/bronze.png",
    };
  },
  created() {
    window.dispatch = this.dispatch;
    window.dispatchgaijia = this.dispatchgaijia;
    window.dispatchgo = this.dispatchgo;
    window.ququ = this.ququ;
    window.pricechange = this.pricechange;
    window.queding = this.queding;
    window.call = this.call;
    window.jishibtn = this.jishibtn;
    window.yuyuebtn = this.yuyuebtn;
    window.shunlubtn = this.shunlubtn;
    window.closemarker = this.closemarker;
    this.img1 = require("../assets/img/cd/wei1.png");
  },

  methods: {
    jiage(e) {
      console.log("选中了", e);
      this.checked1 = e;
    },
    hide() {
      this.showedit = false;
    },
    typejishi() {
      this.jishi = true;
      this.yuyue = false;
      this.shunlu = false;
      this.a = this.aa * this.discount1;
      this.b = this.bb;
      this.c = this.cc * this.discount1;
    },
    typeyuyue() {
      this.yuyue = true;
      this.jishi = false;
      this.shunlu = false;
      this.a = this.aa * this.discount2;
      this.b = this.bb;
      this.c = this.cc * this.discount2;
    },
    typeshunlu() {
      this.shunlu = true;
      this.jishi = false;
      this.yuyue = false;
      this.a = this.aa * this.discount3;
      this.b = this.bb;
      this.c = this.cc * this.discount3;
    },

    suo() {
      this.zhan = true;
      this.$refs.zhanmap.classList.add("shrinkmap");
    },
    zhankai() {
      this.zhan = false;
      this.$refs.zhanmap.classList.remove("shrinkmap");
    },

    close() {
      this.matchs = false;
      //   this.mapyin = true;
      // this.zhis = false;
      // this.shoulis = false;
      // this.btnyin = true;
      //   this.btnyin = false;
      //   this.newbtnyin = true;
      // this.$refs.lie.classList.add("dynamic");
      // this.img = require("../assets/img/cd/list.png");
      // this.img1 = require("../assets/img/cd/wei1.png");

      this.cartype = 1;
    },
    lish() {
      console.log("点击了-----");
      this.car++;
      if (this.car == 3) {
        this.car = 1;
      }
      this.guanbiids = this.detail.订单号;
    },
    beizhuMessage() {
      this.refresh(this.detail.订单号);
      this.rescueIdx = "";
    },
    dingbei(id) {
      this.gaibei++;
      if (this.gaibei == 3) {
        this.gaibei = 1;
      }
      console.log("*/**/*/*/*/*", id);
      this.resdingIds = id;
    },
    async zhipai(uid) {
      let res = await api.OrderDispatch(this.detail.订单号, uid);
      if (res.code == 1) {
        this.$message.success(res.message);
        this.refresh();
      } else {
        this.$message.warning(res.message);
      }
    },

    xiuprice(index, data) {
      if (data.is_self == 0) {
        if (data.is_take == 0) {
          this.getPlatprice(data.uid);

          if (this.gaiindex == -1) {
            this.gaiindex = index;
          } else {
            this.gaiindex = -1;
          }
        } else {
          this.getPlatpriceNow(data.uid);

          if (this.gaiindex == -1) {
            this.gaiindex = index;
          } else {
            this.gaiindex = -1;
          }
        }
      } else {
        this.dispatchgo(this.detail.订单号, data.uid);
      }
    },
    xiuprice2(index, data) {
      if (data.is_self == 0) {
        if (data.is_take == 0) {
          this.getPlatprice(data.uid);

          if (this.gaiindex2 == -1) {
            this.gaiindex2 = index;
          } else {
            this.gaiindex2 = -1;
          }
        } else {
          this.getPlatpriceNow(data.uid);

          if (this.gaiindex2 == -1) {
            this.gaiindex2 = index;
          } else {
            this.gaiindex2 = -1;
          }
        }
      } else {
        this.dispatchgo(this.detail.订单号, data.uid);
      }
    },
    async getPlatprice(uid) {
      let res = await api.getPlatpriceNew(
        uid,
        this.detail.rescue_type,
        this.detail.订单号
      );
      this.chargeType = res.data.chargeType.toString();
      this.funGong(this.chargeType);
      this.aa = res.data.guidePirce;
      this.bb = res.data.guideKm;
      this.cc = res.data.kmPrice;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      if (this.detail.rescue_style == 1) {
        this.a = res.data.guidePirce;
        this.b = res.data.guideKm;
        this.c = res.data.kmPrice;
        this.jishi = true;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 2) {
        this.a = this.aa * this.discount2;
        this.b = res.data.guideKm;
        this.c = this.cc * this.discount2;
        this.jishi = false;
        this.yuyue = true;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 3) {
        this.a = this.aa * this.discount3;
        this.b = res.data.guideKm;
        this.c = this.cc * this.discount3;
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = true;
      }

      this.d = uid;
    },
    async getPlatpriceNow(uid) {
      let res = await api.getPlatpriceNow(this.detail.订单号);
      this.chargeType = res.data.chargeType.toString();
      this.funGong(this.chargeType);
      this.a = res.data.guidePirce;
      this.b = res.data.guideKm;
      this.c = res.data.kmPrice;
      this.d = uid;
      this.aa = res.data.a2;
      this.bb = res.data.a3;
      this.cc = res.data.a4;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      if (this.detail.rescue_style == 1) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 2) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 3) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      }
    },
    // async getPlatpriceMap(rescuetype,uid)
    // {

    // },
    funGong(label) {
      console.log("点击了：", label);
      this.showYiKou = label == 2 ? true : false;
      console.log(this.showYiKou);
    },
    gaicancel() {
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex = -1;
    },
    gaiseal() {
      this.dispatchX();
      this.gaiindex = -1;
    },
    gaicancel2() {
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex2 = -1;
    },
    gaiseal2() {
      this.dispatchX();
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex2 = -1;
    },

    async que() {
      console.log("输入框的值：", this.carNum);
      let zhi = this.carNum;
      let res = await api.getCar(zhi, this.detail.订单号);

      if (this.mapyin == true) {
        this.driverListMap = res.data;
      } else {
        this.driverList = res.data;
      }
    },

    reset() {
      this.carNum = "";
      this.driverList = this.driverList1;
      this.driverListMap = this.driverListMap1;
      if (this.mapyin == true) {
      }
    },

    paiTab() {
      this.mapyin = false;
      this.btnyin = true;
      this.newbtnyin = false;

      this.$refs.lie.classList.add("dynamic");
      this.$refs.di.classList.remove("dynamic");

      // this.$refs.jiahao.classList.remove("newjiahao");

      this.img = require("../assets/img/cd/list.png");
      this.img1 = require("../assets/img/cd/wei.png");
      if (this.mapyin == false) {
        this.img = require("../assets/img/cd/list1.png");
      }
      if (this.mapyin == true) {
        this.img1 = require("../assets/img/cd/wei1.png");
      }
    },

    paiMap() {
      this.mapyin = true;
      this.btnyin = false;
      this.newbtnyin = true;

      this.$refs.lie.classList.remove("dynamic");
      this.$refs.di.classList.add("dynamic");

      // this.$refs.jiahao.classList.add("newjiahao");

      this.img = require("../assets/img/cd/list.png");
      this.img1 = require("../assets/img/cd/wei.png");
      if (this.mapyin == false) {
        this.img = require("../assets/img/cd/list1.png");
      }
      if (this.mapyin == true) {
        this.img1 = require("../assets/img/cd/wei1.png");
      }
    },
    mouseenterTel(index) {
      this.hao = index;
    },
    mouseleaveTel() {
      this.hao = -1;
    },

    mouseenterFun(index) {
      console.log("悬浮--", index);
      this.yaIndex = index;
    },
    mouseleaveFun() {
      this.yaIndex = -1;
    },
    async shoutype(e) {
      this.cartype = e;
      let res_driverMore = await api.OrderGetDriverMore(
        this.detail.订单号,
        this.cartype
      );
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
    },

    async dispatch(id, rescue_type, uid, rescue_style) {
      let res = await api.getPlatpriceNew(uid, rescue_type, id);
      this.chargeType = res.data.chargeType.toString();
      this.aa = res.data.guidePirce;
      this.bb = res.data.guideKm;
      this.cc = res.data.kmPrice;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      this.d = uid;

      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");

      if (rescue_style == 1) {
        this.a = this.aa;
        this.b = this.bb;
        this.c = this.cc;
        jishi.classList.add("color");
        yuyue.classList.remove("color");
        shunlu.classList.remove("color");
      } else if (rescue_style == 2) {
        this.a = this.aa * this.discount2;
        this.b = this.bb;
        this.c = this.cc * this.discount2;
        jishi.classList.remove("color");
        yuyue.classList.add("color");
        shunlu.classList.remove("color");
      } else if (rescue_style == 3) {
        this.a = this.aa * this.discount3;
        this.b = this.bb;
        this.c = this.cc * this.discount3;
        jishi.classList.remove("color");
        yuyue.classList.remove("color");
        shunlu.classList.add("color");
      }

      let xiujiage = document.getElementById("gaiprice3");

      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      document.getElementById("rescueId").value = id;

      if (this.chargeType == "1") {
        $("#yikouprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.add("yinprice");
        a.value = this.a;
      } else {
        $("#gongliprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.remove("yinprice");
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }

      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    async dispatchgaijia(id, uid, rescue_style) {
      let res = await api.getPlatpriceNow(id);
      this.chargeType = res.data.chargeType.toString();
      this.a = res.data.guidePirce;
      this.b = res.data.guideKm;
      this.c = res.data.kmPrice;
      this.d = uid;

      this.aa = res.data.a2;
      this.bb = res.data.a3;
      this.cc = res.data.a4;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;

      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");

      jishi.classList.remove("color");
      yuyue.classList.remove("color");
      shunlu.classList.remove("color");

      let xiujiage = document.getElementById("gaiprice3");

      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      document.getElementById("rescueId").value = id;

      if (this.chargeType == "1") {
        $("#yikouprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.add("yinprice");
        a.value = this.a;
      } else {
        $("#gongliprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.remove("yinprice");
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }

      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    pricechange() {
      let danrido = document.getElementById("priceyin");
      console.log("触发单选");
      if (yikouprice.checked == true) {
        danrido.classList.add("yinprice");
      } else if (gongliprice.checked == true) {
        danrido.classList.remove("yinprice");
      }
    },
    ququ() {
      let xiujiage = document.getElementById("gaiprice3");
      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    jishibtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", jishi);
      jishi.classList.add("color");
      yuyue.classList.remove("color");
      shunlu.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = this.aa;
        this.b = this.bb;
        this.c = this.cc;
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },
    yuyuebtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", yuyue);
      jishi.classList.remove("color");
      yuyue.classList.add("color");
      shunlu.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = this.aa * this.discount2;
        this.b = this.bb;
        this.c = this.cc * this.discount2;
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },
    shunlubtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", shunlu);
      shunlu.classList.add("color");
      yuyue.classList.remove("color");
      jishi.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = this.aa * this.discount3;
        this.b = this.bb;
        this.c = this.cc * this.discount3;
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },

    async queding() {
      let that = this;
      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      let rescueId = document.getElementById("rescueId");
      let chargetype = 1;
      if (yikouprice.checked == true) {
        chargetype = 1;
      } else if (gongliprice.checked == true) {
        chargetype = 2;
      }
      let res = await api.OrderDispatchX(
        rescueId.value,
        that.d,
        chargetype,
        a.value,
        b.value,
        c.value
      );
      if (res.code == 1) {
        that.$message.success(res.message);
        that.refreshX(rescueId.value);
      } else {
        that.$message.warning(res.message);
      }
    },

    //地图里面指派改派
    async dispatchgo(id, uid) {
      let that = this;
      console.log(this.detail.订单号);
      this.detail.订单号 = id;
      let res = await api.OrderDispatch(id, uid);
      if (res.code == 1) {
        that.$message.success(res.message);
        that.refresh();
      } else {
        that.$message.warning(res.message);
      }
    },
    async dispatchX() {
      let that = this;
      let res = await api.OrderDispatchX(
        that.detail.订单号,
        that.d,
        that.chargeType,
        that.a,
        that.b,
        that.c
      );
      if (res.code == 1) {
        that.$message.success(res.message);
        that.refresh();
      } else {
        that.$message.warning(res.message);
      }
    },
    async refresh() {
      let res = await api.OrderDetail(this.detail.订单号);
      console.log("订单详情", res);
      this.detail = res.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      let res_matchorder = await api.OrderMatch(this.detail.订单号);
      this.driverListMap = res_matchorder.data;
      this.driverListMap1 = res_matchorder.data;
      this.driverList = res_matchorder.data;
      this.driverList1 = res_matchorder.data;
      console.log("匹配表格", this.driverListMap);

      // let res_driverMore = await api.OrderGetDriverMore(
      //   this.detail.订单号,
      //   this.cartype
      // );
      // this.driverListMap = res_driverMore.data;
      // this.driverListMap1 = res_driverMore.data;
      // this.driverList = res_driverMore.data;
      // this.driverList1 = res_driverMore.data;
    },
    async refreshX(id) {
      let res = await api.OrderDetail(id);
      console.log("订单详情", res);
      this.detail = res.data;
      // let res_driver = await api.OrderGetDriverMore(id, 1);
      // console.log("司机列表", res_driver);
      // this.driverList = res_driver.data;
      // this.driverList1 = res_driver.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(id);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      let res_driverMore = await api.OrderGetDriverMore(id, this.cartype);
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
      this.driverList = res_driverMore.data;
      this.driverList1 = res_driverMore.data;
    },

    async partList() {
      let res = await api.OrderDetail(this.partId);
      console.log("订单详情", res);
      this.detail = res.data;
      // let res_driver = await api.OrderGetDriverMore(this.partId, 1);
      // console.log("司机列表", res_driver);
      // this.driverList = res_driver.data;
      // this.driverList1 = res_driver.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      let res_driverMore = await api.OrderGetDriverMore(
        this.partId,
        this.cartype
      );
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
      this.driverList = res_driverMore.data;
      this.driverList1 = res_driverMore.data;
    },

    async weishouli() {
      let res = await api.OrderDetail(this.weishouliId);
      console.log("订单详情", this.weishouliId, "qqqqq", res);
      this.detail = res.data;
      // let res_driver = await api.OrderGetDriverMore(this.weishouliId, 1);
      // console.log("司机列表", res_driver);
      // this.driverList = res_driver.data;
      // this.driverList1 = res_driver.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      let res_driverMore = await api.OrderGetDriverMore(
        this.weishouliId,
        this.cartype
      );
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
      this.driverList = res_driverMore.data;
      this.driverList1 = res_driverMore.data;
    },

    async recycle() {
      let res = await api.OrderRecycle(this.detail.订单号);
      if (res.code == 1) {
        // alert(res.message);
        this.$message.success(res.message);
        this.refresh();
      } else {
        // alert(res.message);
        this.$message.warning(res.message);
      }
    },
    edit() {
      this.showedit = true;
      // this.showedit++;
      // if (this.showedit == 3) {
      //   this.showedit = 1;
      // }
      this.xiugaiidx = this.detail.订单号;
    },
    // call(mobile) {
    //   let state = phonebar.checkSeatState();
    //   console.log(state);

    //   if (state == 1) {
    //     phonebar.call(mobile);
    //   } else {
    //     this.$message.warning("请先完成当前通话");
    //   }
    // },
    call(tel, str) {
      tel = tel.toString();
      str = str.toString();
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },

  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    matches(newval) {
      console.log(this.matchrescueid);
      this.matchs = newval;
      this.detail.订单号 = this.matchrescueid;
      if (newval >= 0) {
        this.refresh();
      }
    },
    getEmic(newval) {
      this.emicreg = newval;
    },
  },
};
</script>

<style lang="less">
.dingyin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.p3 {
  // margin: 0;
  margin: 10px;
}
.amp-title-l {
  margin-left: 44px;
}
.map-main {
  width: 410px;
  // height: 170px;
  padding-left: 20px;
  padding-top: 10px;
  opacity: 1;
  box-sizing: border-box;
  // background-color: #fff;
  position: relative;
}
.amp-btn1 {
  // margin-left: 10px;
  // padding: 1px 8px;
  font-size: 12px;
  float: right;
}

.amp-title {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.amp-title-main,
.amp-title-m,
.amp-tit-m,
.amp-title-l {
  width: 126px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.amp-title-main {
  margin-left: 30px;
}

.amp-title-img {
  width: 18px;
  height: 18px;
  float: right;
  margin-top: 4px;
  margin-right: 104px;
  margin-left: 260px;
  margin-top: -18px;
}
.amp-title-distance {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  float: right;
  margin-right: 10px;
  margin-top: -24px;
}

.amp-tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 30px;
  color: #2c68ff;
  opacity: 1;
}

.amp-leisure {
  float: left;
  font-size: 12px;
  margin-left: 280px;
  margin-top: -50px;
  width: 88px;
  height: 30px;
  background-color: #eefbf4;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #27cc8b;
  opacity: 1;
}
.amp-gai,
.amp-qu {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}
.amp-qu {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
}
.amp-gai {
  height: 26px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}
.amp-gai-red {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  height: 26px;
  background: red;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}

.amap-info-content {
  padding: 0;
}
.amap-info-contentContainer.bottom-left {
  position: relative;
}

.haoBox {
  cursor: pointer;
  position: relative;
}
.haoBox2 {
  cursor: pointer;
  display: flex;
}

.maphaoma {
  width: 170px;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  // left: 50px;
  top: 18px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 10;
  display: none;
}
.haoBox:hover .maphaoma {
  display: block;
}
.haoBox2:hover .maphaoma {
  display: block;
}

.maphaomaMain {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.mapBox {
  width: 600px;
  // width: 345px;
  height: auto;
  // min-height: 350px;
  max-height: 600px;
  border-radius: 8px;
  background-color: #fff;
  // position: relative;
  padding-bottom: 30px;
  box-sizing: border-box;
  // box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
  //   0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  position: relative;
}
.mapBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.mapBoxNav {
  // position: absolute;
  // top: 0;
  width: 600px;
  height: 50px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  // padding-right: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  z-index: 5;
  background-color: #fff;
  padding-left: 20px;
}
.mapBoxTit {
  font-size: 14px;
}
.gradeimg {
  width: 16px;
  height: 20px;
  margin-right: 10px;
  margin-top: -3px;
}
.amap-info-close {
  z-index: 5;
}
.carXinXi,
.taskBox,
.pressSheet {
  margin: 10px 24px;
  width: 550px;
}
.carXinXiDing {
  margin-top: 20px;
}
.taskBoxNav,
.pressSheetNav {
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid #2c68ff;
  // background-color: pink;
  // width: 550px;
}
.carXinXiNav2 {
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid #2c68ff;
}
strong {
  font-size: 16px;
  font-weight: 500;
}
.tool {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #000;
  font-size: 12px;
}

.zhuangtai {
  background-color: #d9e4ff;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
  border: 1px solid #d9e4ff;
}
.tool,
.zhuangtai {
  margin-left: 10px;
}
.pressSheetNum {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  font-size: 12px;
  margin-left: 10px;
}
.xinxiBox {
  display: flex;
  // align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}
.newtimebox {
  justify-content: space-between;
}
.newtimeyin {
  display: none;
}
// .newtime {
//   display: flex;
// }
.xinRight {
  width: 330px;
  display: flex;
}
.xinLeft {
  width: 220px;
  display: flex;
  position: relative;
}
.xiTit {
  // margin-right: 10px;
  // width: 150px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
}
.xiTit2 {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
}

.xinTxt,
.taskTit2 {
  width: 500px;
  display: flex;
}
.xinTxt2 {
  width: 130px;
}
// .xiTxt {
//   width: 220px;
// }
.callImg {
  margin-left: 10px;
}
.taskTit {
  width: 500px;
}
// .xinxiaddress {
//   width: 500px;
// }
.maptable {
  margin-top: 10px;
}
thead {
  background-color: #f5f5f5;
  height: 32px !important;
}
.partner,
.taskPoint,
.destination {
  width: 150px;
}
.serviceType {
  width: 100px;
}

// tbody tr {
//   height: 70px !important;
// }
.partner,
.serviceType,
.taskPoint,
.destination {
  text-align: center;
}
.td-serviceType {
  text-align: center;
}
td {
  // padding: 10px;
  text-align: left;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}
.bg_color2 {
  background-color: #fafafa !important;
}
</style>

<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 540px;
  height: 360px;
}
.box {
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 4;
  // background-color: #fff;
}
.box2 {
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  // background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  position: absolute;
  top: 36px;
  left: -29px;
}
.ddd {
  float: left;
  width: 22px;
  height: 22px;
  margin-top: 3px;
  margin-left: -15px;
}
.box-main,
.boxmain2 {
  width: 1500px;
  // width: 720px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
.box-main {
  height: 920px;
}
.boxmain2 {
  width: 1500px;
  // width: 1720px;
  height: 900px;
  box-sizing: border-box;
}
.jiaMain {
  width: 1720px;
}
.newtitlemain {
  width: 300px !important;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  padding-left: 20px;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
  padding-top: 20px;
}
i {
  font-style: normal;
}
.xin {
  cursor: pointer;
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 40px;
  right: 20px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 30px;
  right: 60px;
}
.order {
  //width: 280px;
  height: 21px;
  font-size: 16px;
  padding-left: 7px;
  margin-left: 20px;
  // margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
  position: relative;
}
.take,
.take1,
.take2 {
  display: inline-block;
  // width: 64px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
}
.take1 {
  color: #8c05bb;
  background: #f2ccff;
}
.take2 {
  color: #ff9100;
  background: #fff1e0;
}
.order-main {
  // width: 510px;
  height: auto;
  opacity: 1;
  //   background-color: orange;
}
.p1 {
  // margin-left: 30px;
  // padding-left: 7px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
}
.receiving {
  width: 700px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.xiangmu {
  // margin-left: 20px;
  display: flex;
}
.newjiahao {
  width: 300px !important;
}
.yanse {
  color: #2c68ff;
}

.remBox,
.diBox {
  display: flex;
  // flex-direction: column;
  // margin-left: 37px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 30px;
  box-sizing: border-box;
}
.diBox {
  width: 700px;
}
.remBox {
  width: 100%;
}

.remTit {
  margin-right: 30px;
}

.diTxt {
  color: #2c68ff;
}
.remTxt {
  // width: 500px;
  width: 1300px;
}
.diTxt {
  width: 500px;
}

.hezuo {
  display: flex;
  padding-left: 30px;
  box-sizing: border-box;
  // justify-content: center;
}

.owner-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.p2 {
  width: 400px;
  // padding-left: 7px;
  // margin-left: 30px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.title {
  // width: 56px;
  margin-right: 30px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.title-main,
.title-main2,
.title-main3 {
  // height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.title-main2 {
  width: 126px;
}
.title-main3 {
  width: 250px;
}

.title-img {
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 4px;
  margin-right: 104px;
  margin-left: 260px;
  margin-top: -18px;
}
.title-distance {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  float: right;
  margin-right: 10px;
  margin-top: -24px;
}

.tit {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  // margin-left: 96px;
  color: #2a3346;
  opacity: 1;
}
.tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 30px;
  color: #2c68ff;
  opacity: 1;
}
.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
#peoper {
  // height: 21px;
  font-size: 16px;
  // margin-top: 45px;
  // line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}
.owner {
  width: 500px;
  position: relative;
}
.tiao,
.tiao1,
.tiao2 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 4px;
  left: -1px;
}
.tiao1 {
  position: absolute;
  top: 3px;
  left: 0;
}
#peoper {
  position: relative;
}
.tiao2 {
  position: absolute;
  top: 3px;
  left: 0;
}

.depart {
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-right: 4px;
}
.depart-main {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  vertical-align: middle;
}

.jianju {
  margin-top: 0;
}
.quan,
.quan1 {
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 50%;
  opacity: 1;
  box-sizing: border-box;
}
.quan {
  border: 3px solid #2c68ff;
}
.quan1 {
  border: 3px solid #27cc8b;
}
.site {
  float: right;
  margin-right: 27px;
}
.site /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
  position: absolute;
  bottom: -13px;
  border-radius: 0;
}

.btn {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.alter,
.cancel {
  width: 80px;
  height: 32px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.cancel {
  vertical-align: middle;
}
.shuaxin {
  width: 80px;
  height: 32px;
  border: 1px solid #727e96;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.recycle {
  width: 80px;
  height: 32px;
  margin-right: 10px;
  background-color: #2c68ff;
  border: 0;
  color: #fff;
  cursor: pointer;
}

// 地图
.map {
  width: 540px;
  height: 360px;
  margin-top: 10px;
  // margin-left: 30px;
  background: #9ea3b9;
  border: 1px solid #707070;
  opacity: 1;
}

.gai,
.qu {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
}
.qu {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
}
.gai {
  height: 26px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}
.leisure {
  float: left;
  font-size: 12px;
  margin-left: 280px;
  margin-top: -50px;
  width: 88px;
  height: 30px;
  background-color: #eefbf4;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #27cc8b;
  opacity: 1;
}
.call {
  width: 20px;
  height: 20px;
  background: url("../assets/img/index/call.png");
  border: none;
  cursor: pointer;
  margin-left: 5px;
  background-size: contain;
}

.biao {
  float: left;
  margin-left: 50px;
}
.list,
.ditu {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 9px;
  cursor: pointer;
}
.list:hover {
  background: #eaf0ff;
}
.ditu:hover {
  background: #eaf0ff;
}

.listImg,
.dituImg {
  width: 24px;
  height: 24px;
}
.listTit,
.dituTit {
  font-size: 14px;
  // color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}
.qie {
  float: left;
}
.pai {
  float: left;
}
.paiList {
  // float: left;
  width: 640px;
  margin-top: 30px;
  padding-bottom: 50px;
  margin-left: 30px;
}
.int {
  width: 495px;
  height: 32px;
  padding-left: 12px;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  outline: none;
}
.int:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}

table {
  // width: 640px;
  width: 100%;
  // height: 360px;
  margin-top: 13px;
  font-weight: 400;
  border: 1px solid #f0f0f0;
}
thead {
  background-color: #f5f5f5;
  height: 32px;
}
th {
  text-align: center;
  font-weight: 400;
  border: 1px solid #f0f0f0;
}
.jzhongduanTit {
  position: relative;
}
.newrenwu {
  padding: 0 32px;
  box-sizing: border-box;
}
// .jiedan {
//   width: 200px;
// }
.zhongduan {
  width: 150px;
}
.jishixinxi{
  width: 250px;
}
.dingdanxinxi {
  width: 100px;
}
.cartype,
.information {
  width: 250px;
}
.juli {
  width: 80px;
}
.current{
  width: 150px;
}
.operation {
  width: 100px;
}
td {
  text-align: center;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  font-size: 14px;
}
.juliTit {
  position: relative;
}

.operBtn,
.operBtn1,
.operBtn2 {
  display: inline-block;
  width: 40px;
  height: 21px;
  text-align: 21px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
}
.operBtn {
  background: #2c68ff;
  color: #fff;
}
.operBtn1 {
  background: #f5f5f5;
  color: #000;
}
.operBtn2 {
  background: red;
  color: #fff;
}
.informationImg,
.yaImg {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  cursor: pointer;
}
// .informationTit,
// .informationTit2 {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .informationTit2 {
//   width: 400px;
//   box-sizing: border-box;
// }
.jiimg {
  margin-right: 5px;
}
.hovertel {
  cursor: pointer;
}

.yaYin {
  width: 540px;
  // height: 200px;
  background-color: #fff;
  position: absolute;
  // left: 23px;
  z-index: 5;
  border-radius: 8px;
  box-shadow: 0 0 5px 3px #e0e0e0;
}
.dingyaYin {
  position: absolute;
  left: 150px;
}
.yaYin th,
.yaYin td {
  border: 0;
}
.yaYin tr {
  height: 70px !important;
}
.yaYintable {
  width: 100%;
  margin: 0;
}
.mapTel {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.mapTel:hover .haoma {
  display: block;
}

.haoma {
  width: 170px;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  left: -20px;
  z-index: 5;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  display: none;
}
.haomaMain {
  display: flex;
  margin: 5px 0;
}

thead {
  background-color: #f5f5f5;
  height: 32px !important;
}
.serviceType {
  width: 100px;
}
.taskPoint,
.destination {
  width: 220px;
}
.td-serviceType {
  padding-left: 20px;
  text-align: left;
}
.td-taskPoint,
.td-destination {
  text-align: left;
}

.xianImg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}
.dynamic {
  color: #2c68ff;
  background: #eaf0ff;
}

// 加宽样式
.jiamap {
  width: 1670px;
  height: 814px;
}
.jiamapmain {
  width: 100% !important;
  height: 100% !important;
}
.jiapai {
  width: 1450px;
  box-sizing: border-box;
}
.paiNav {
  width: 100%;
}
.jiap {
  width: 400px;
  padding: 0 !important;
}
.jiaBox {
  // width: 830px;
  padding: 0;
}
.jiaTit {
  // width: 700px;
  width: 300px;
  padding: 0;
}
.jieTit {
  width: 300px;
  padding: 0;
  color: #000;
}
.taiImg {
  width: 40px !important;
  height: 40px !important;
}
.jiedanren {
  width: 700px;
  display: flex;
  align-items: center;
}

.newxuan {
  float: right;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.newxuan
  /deep/
  .el-checkbox-button.is-checked:first-child
  .el-checkbox-button__inner {
  border-radius: 0;
}
.newxuanbox {
  height: 100%;
  border: 1px solid #e2e2e5;
  display: flex;
  align-items: center;
  margin-left: 10px;
  box-sizing: border-box;
}
.tiao3 {
  display: inline-block;
  width: 0px;
  height: 14px;
  // margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.zhang,
.shoufeiz {
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.zhang {
  color: #9e9e9e;
}
.shoufeiz {
  color: #2c68ff;
}
</style>
<style>
.amap-marker-label {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.carnumhao {
  width: 110px;
  padding: 3px 5px;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaog {
  width: 110px;
  padding: 3px 5px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaotake {
  display: flex;
  align-items: center;
  width: 100px;
  padding: 3px 5px;
  background-color: red;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaoyellow {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  border: solid 2px;
  border-color: red;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaoyellowg {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  border: solid 2px;
  border-color: red;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhao1 {
  width: 180px;
  padding: 3px 5px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -75px;
  text-align: center;
}
.newbtn {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.mapshua {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.mapxin {
  color: #2c68ff;
  font-size: 16px;
  font-weight: 400;
}
.newmapmain {
  display: flex;
}
.jiabeiBox {
  /* width: 1700px !important; */
  width: 500px !important;
  padding: 0 !important;
}
.jiabeiTit {
  /* width: 1550px !important; */
  width: 350px !important;
}
/* .newjiap{
  width: 400px;
  padding-left: 65px !important;
} */
.mapimg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.mapxinxi {
  width: 500px;
  height: 814px;
  background-color: #fff;
  /* background-color: gray; */
  opacity: 0.8;
  position: absolute;
  right: 17px;
  top: 11px;
  box-sizing: border-box;
  /* overflow: hidden;
   overflow-y: scroll; */
}
.shrinkmap {
  width: 0 !important;
}

.mapxinxibox {
  padding: 0px 20px 0 30px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
.suomap {
  position: absolute;
  top: 45%;
  cursor: pointer;
}
.fanimg,
.zhanimg {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -10px;
}
.zhanimg {
  transform: rotate(180deg);
}

/* 修改价格 */
.operationTit {
  position: relative;
}
.gaiprice,
.gaiprice2,
#gaiprice3 {
  width: 450px;
  height: 200px;
  background-color: #fff;

  z-index: 5;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 2px #ccc;
  border-radius: 4px;
}
.gaiprice {
  width: 600px;
  position: absolute;
  right: 130px;
  top: -50px;
}
.gaiprice2 {
  position: absolute;
  right: 70px;
  top: -50px;
}
#gaiprice3 {
  width: 550px;
  position: absolute;
  right: 15px;
  top: 110px;
  display: none;
}
.gaiprice::before,
.gaiprice2::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: -25px;
  top: 55px;
  border: 13px solid #fff;
  border-top-color: transparent;
  /* border-left-color: transparent; */
  border-right-color: transparent;
  border-bottom-color: transparent;
}
#gaiprice3::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 30px;
  top: -27px;
  border: 13px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  /* border-bottom-color: transparent; */
}

.pricelabel {
  margin-right: 20px;
}
.gaipricetit,
.name1 {
  display: flex;
  align-items: center;
}
.name1 {
  margin-top: 10px;
}
.elect {
  padding-left: 80px;
  margin-top: 10px;
  box-sizing: border-box;
}
#electbox2,
#electbox3,
#electbox4 {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 70px;
  box-sizing: border-box;
}
.elect1,
.elect2 {
  margin-right: 10px;
}
.elect,
.electbox {
  display: flex;
  align-items: center;
}
.elect-span1 {
  width: 65px;
  height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #2a3346;
  opacity: 1;
  border: 1px solid #ccc;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
}
.gaipricetit {
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
}
.typexuan {
  font-weight: normal;
}
.gaipricebtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.reset,
.deter,
.detersou,
.resetsou {
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin-left: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.resetsou,
.detersou {
  width: 100px;
}
.reset,
.resetsou {
  border: 1px solid #d9d9d9;
}
.reset:hover {
  background: #edf2fc;
  border: 1px solid #8aabfe;
}
.resetsou:hover {
  background: #edf2fc;
  border: 1px solid #8aabfe;
}
.deter,
.detersou {
  background: #2c68ff;
  color: #fff;
  border: 0;
}
.deter:hover {
  background: #0a4aed;
}
.detersou:hover {
  background: #0a4aed;
}
.yingaiprice3 {
  display: block !important;
  /* opacity: 1 !important; */
}
#gongliprice {
  margin-left: 10px;
}
.yinprice {
  display: none;
}
.yikoutit {
  margin-left: 5px;
}

.amap-info-close {
  top: 10px;
}

#btns button {
  width: 60px;
  height: 30px;
  float: left;
  border: 0;
  cursor: pointer;
}
.btns button {
  width: 60px;
  height: 30px;
  float: left;
  border: 0;
  cursor: pointer;
}
#divs div {
  width: 300px;
  height: 100px;
  font-size: 60px;
  padding-top: 60px;
  background-color: #eb923f;
  text-align: center;
  display: none;
}
.color {
  background-color: #2c68ff;
  color: #fff;
}

.pimain {
  display: flex;
  margin-top: 20px;
}
.facar {
  margin-top: 10px;
}
.zhuang {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  color: #fff;
  margin-top: 3px;
  box-sizing: border-box;
  margin-left:13px;
}
</style>
