import http from "../util/http.js";
//订单详情
export const OrderDetail = (rescueId) => {
  return http
    .get("/order/detail", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

export const ComplaintDetail = (rescueId) => {
  return http
    .get("/order/complaint-detail", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

export const OrderDetailM = (rescueId) => {
  return http
    .get("/order/detail-more", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

//订单日志
export const OrderLog = (rescueId) => {
  return http
    .get("/order/order-log", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

export const EmicLog = (id) => {
  return http
    .get("/order/get-emic-log", {
      params: {
        id,
      },
    })
    .then((res) => res.data);
};
//订单照片
export const OrderPhoto = (rescueId) => {
  return http
    .get("/order/order-photo", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

export const OrderPhotoSh = (rescueId) => {
  return http
    .get("/order/order-photo-sh", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

export const OrderPhotoNew = (rescueId) => {
  return http
    .get("/order/order-photo-new", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

//查找附近可接单司机
export const OrderGetDriver = (rescueId) => {
  return http
    .get("/order/get-nearby-tech", {
      params: {
        rescueId: rescueId,
        km: 50,
      },
    })
    .then((res) => res.data);
};

export const OrderGetDriverMore = (rescueId, type, iskj) => {
  return http
    .get("/order/get-nearby-tech-more", {
      params: {
        rescueId: rescueId,
        type: type,
        iskj: iskj,
        km: 30,
      },
    })
    .then((res) => res.data);
};

export const OrderGetDriverMoreX = (rescueId, type, iskj, newtype) => {
  return http
    .get("/order/get-nearby-tech-more-x", {
      params: {
        rescueId: rescueId,
        type: type,
        iskj: iskj,
        km: 30,
        newtype: newtype,
      },
    })
    .then((res) => res.data);
};

export const OrderGetPoly = (rescueId) => {
  return http
    .get("/order/get-plan-poly", {
      params: {
        id: rescueId,
      },
    })
    .then((res) => res.data);
};

//订单调度
export const OrderDispatch = (rescueId, uid) => {
  return http
    .post("/order/do-dispatch", {
      rescueId: rescueId,
      uid: uid,
    })
    .then((res) => res.data);
};

export const OrderDispatchX = (
  rescueId,
  uid,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice
) => {
  return http
    .post("/order/do-dispatch-x", {
      rescueId: rescueId,
      uid: uid,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
    })
    .then((res) => res.data);
};

//订单回收
export const OrderRecycle = (rescueId) => {
  return http
    .post("/order/do-recycle", {
      rescueId: rescueId,
    })
    .then((res) => res.data);
};

export const OrderCanEmpty = (rescueId) => {
  return http
    .get("/order/can-empty", {
      params: {
        rescueId: rescueId,
      },
    })
    .then((res) => res.data);
};

export const orderClose = (rescueId, reason, closetype) => {
  return http
    .post("/order/do-close", {
      rescueId: rescueId,
      reason: reason,
      type: 1,
      closetype: closetype,
    })
    .then((res) => res.data);
};

export const orderEmpty = (rescueId, reason) => {
  return http
    .post("/order/do-empty", {
      rescueId: rescueId,
      reason: reason,
      type: 1,
    })
    .then((res) => res.data);
};

export const orderVisit = (rescueId) => {
  return http
    .post("/order/do-returnvisit", {
      rescueId: rescueId,
    })
    .then((res) => res.data);
};

export const orderComplain = (rescueId, title, content, type, classes) => {
  return http
    .post("/order/do-complaint", {
      rescueId: rescueId,
      title: title,
      content: content,
      type: type,
      classes: classes,
    })
    .then((res) => res.data);
};

export const orderView = (rescueId, type, content, reason) => {
  return http
    .post("/order/do-review", {
      rescueId: rescueId,
      type: type,
      content: content,
      reason: reason,
    })
    .then((res) => res.data);
};

export const rescueAppeal = (rescueId, content) => {
  return http
    .post("/order/rescue-appeal", {
      rescueId: rescueId,
      content: content,
    })
    .then((res) => res.data);
};

export const rescueTechCancel = (rescueId) => {
  return http
    .post("/order/rescue-tech-cancel", {
      rescueId: rescueId,
    })
    .then((res) => res.data);
};

export const getCloseReason = (type) => {
  return http
    .post("/order/get-close-reason", {
      type: type,
    })
    .then((res) => res.data);
};

export const getCar = (keyword, id) => {
  return http
    .post("/order/get-car", {
      keyword: keyword,
      id: id,
    })
    .then((res) => res.data);
};

export const getCarX = (keyword, id, newtype) => {
  return http
    .post("/order/get-car-x", {
      keyword: keyword,
      id: id,
      newtype: newtype,
    })
    .then((res) => res.data);
};

export const getPlatprice = (uid, rescueType) => {
  return http
    .get("/order/plat-price", {
      params: {
        uid: uid,
        rescue_type: rescueType,
      },
    })
    .then((res) => res.data);
};

export const getPlatpriceNow = (id) => {
  return http
    .get("/order/plat-price-now", {
      params: {
        id: id,
      },
    })
    .then((res) => res.data);
};

export const getPlatpriceNew = (uid, rescueType, id) => {
  return http
    .get("/order/plat-price-new", {
      params: {
        uid: uid,
        rescue_type: rescueType,
        id: id,
      },
    })
    .then((res) => res.data);
};

export const OrderMatch = (id) => {
  return http
    .get("/order/order-match-x", {
      params: {
        id: id,
      },
    })
    .then((res) => res.data);
};

export const OrderOpen = (id, order) => {
  return http
    .post("/order/open-order", {
      order: order,
      id: id,
    })
    .then((res) => res.data);
};

export const orderopprice = (uid, id) => {
  return http
    .get("/order/get-user-op-price", {
      params: {
        uid: uid,
        id: id,
      },
    })
    .then((res) => res.data);
};

export const orderoppricenow = (id) => {
  return http
    .get("/order/get-order-op-price", {
      params: {
        id: id,
      },
    })
    .then((res) => res.data);
};

export const OrderOpPush = (id, uid, chargetype, a, b, c) => {
  return http
    .get("/order/order-op-push", {
      params: {
        uid: uid,
        id: id,
        chargetype: chargetype,
        a: a,
        b: b,
        c: c,
      },
    })
    .then((res) => res.data);
};

export const OrderOpMoney = (id, chargetype, a, b, c) => {
  return http
    .get("/order/order-op-price-change", {
      params: {
        id: id,
        chargetype: chargetype,
        a: a,
        b: b,
        c: c,
      },
    })
    .then((res) => res.data);
};

export const orderBuchuan = (id, pic) => {
  return http
    .post("/order/order-pic-buchuan", {
      id: id,
      pic: pic,
    })
    .then((res) => res.data);
};

export const postTips = (type, uid, username) => {
  return http
    .post("/tech/tips", {
      type: type,
      uid: uid,
      username: username,
    })
    .then((res) => res.data);
};

export const getgplist = () => {
  return http
    .get("/sys/get-g-p-reason", {
      params: {},
    })
    .then((res) => res.data);
};

export const OrderRecycleNew = (rescueId, reason) => {
  return http
    .post("/order/do-recycle-new", {
      rescueId: rescueId,
      reason: reason,
    })
    .then((res) => res.data);
};

export const OrderOpPushNew = (id, uid, chargetype, a, b, c, reason) => {
  return http
    .get("/order/order-op-push-new", {
      params: {
        uid: uid,
        id: id,
        chargetype: chargetype,
        a: a,
        b: b,
        c: c,
        reason: reason,
      },
    })
    .then((res) => res.data);
};

export const OrderDispatchXNew = (
  rescueId,
  uid,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  reason
) => {
  return http
    .post("/order/do-dispatch-x-new", {
      rescueId: rescueId,
      uid: uid,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      reason: reason,
    })
    .then((res) => res.data);
};

export const OrderDispatchNew = (rescueId, uid, reason) => {
  return http
    .post("/order/do-dispatch-new", {
      rescueId: rescueId,
      uid: uid,
      reason: reason,
    })
    .then((res) => res.data);
};
