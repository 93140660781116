import Cookies from "js-cookie";

var websock = null;

var uuid = Cookies.get("uuid")?Cookies.get("uuid"):"73836387-0000-0000-0000-0000-0000000023";
var global_callback = null;
//var wsuri = "wss://internal.ttf-cti.com:10085/ola_socket";
var wsuri = "wss://113.31.113.243:10085/ola_socket";
//var wsuri = "wss://tianrun.xswitch.cn:10085/ola_socket";
var user = '';
var psd = '';
var que = Cookies.get("queId")?Cookies.get("queId"):'10047';

function createOlaSocket(username,password,callback) {
    if (websock == null || typeof websock !== WebSocket) {
      initWebSocket(username,password,callback);
    } 
}

function initWebSocket(username,password,callback) {

  user = username;
  psd = password;
  //var password = password;
  global_callback = callback;
  // 初始化websocket
  websock = new WebSocket(wsuri);
  websock.onmessage = function (e) {
      websocketonmessage(e);
  };
  websock.onclose = function (e) {
      websocketclose(e);
  };
  websock.onopen = function (e) {
      websocketOpen(e);
  };

  // 连接发生错误的回调方法
  websock.onerror = function () {
    console.log("WebSocket连接发生错误");
  
  };
}

// 实际调用的方法
function sendSock(agentData ) {
  
  if (websock.readyState === websock.OPEN) {
    websocketsend(agentData);
  } else if (websock.readyState === websock.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData);
    }, 1000);
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData);
    }, 1000);
  }
}

function closeSock() {
  websock.close();
}

// 数据接收
function websocketonmessage(msg) 
{
  //console.log("收到数据："+msg.data);
  global_callback(msg.data);
}

function send(msg)
{
  msg.uuid = uuid;
  console.log(msg);
  websocketsend(JSON.stringify(msg));
}

// 数据发送
function websocketsend(agentData) {
  websock.send(agentData);
}

// 关闭
function websocketclose(e) {
  console.log("connection closed (" + e.code + ")");
  websocketOpen(e);
}

//{type: "onhook"}
function websocketOpen(e) 
{
  console.log("连接打开");
  send({cmd: "auth", args:{username:user, password:psd, accept:"application/json"}});  
}

function olalogin()
{
   let args = {queue:que, extn: user,type:'onhook'};

   send({action:"api", cmd:"login", args: args });
}

function olaloginout() {
  send({action:"api", cmd:"logout", args:{extn: user}});
}

function olasubscribe(k) {

  send({cmd:"subscribe", args:{key:k}});

}

function olaunsubscribe(k) {

  send({cmd:"unsubscribe", args:{key:k}});

}


function olaget_agent_state() {
  send({action:"api", cmd:"get_agent_state", args:{extn: user}});
}

function oladial(dst,otherStr)
{
  send({action:"api", cmd:"dial", args:{extn: user, dest: dst,otherStr:otherStr}});
}


function go_ready() {
  return send({action:"api", cmd:"go_ready", args:{extn: user}});
}


function hold() {
  send({action:"api", cmd:"hold", args:{extn: user}});
}

function unhold() {
  send({action:"api", cmd:"unhold", args:{extn: user}});
}

function hangup() {
  send({action:"api", cmd:"hangup_other", args:{extn: user}});
}

function transfer(src){
  send({action:"api", cmd:"transfer", args:{extn:user,dest:src}});
}

function three_way(dst){
  send({action:"api", cmd:"monitor", args:{extn: dst,dest: user,three_way:"true"}});
}

export {createOlaSocket,olalogin,olasubscribe,olaget_agent_state,oladial,go_ready,hold,unhold,hangup,olaloginout,transfer,three_way};
