<template>
  <div class="beiBox" v-if="beis || dingbeis">
    <div class="main">
      <div class="beiNav">
        <h1>添加备注</h1>
        <img
          class="cha"
          @click="close"
          src="../assets/img/index/cha.png"
          alt=""
        />
      </div>

      <div class="beiMain">
        <label for="">备注:</label>
        <div class="wenben">
          <textarea
            name=""
            class="beiTxt"
            v-model="title"
            maxlength="255"
            placeholder="请输入需要备注的文字"
          ></textarea>
          <span>{{ this.titleMax - this.title.length }}/255</span>

          <el-radio-group v-model="radio">
            <el-radio label="0">公开</el-radio>
            <el-radio label="1">私有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="btn">
        <button class="save" @click="bao">保存</button>
        <button class="xiao" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/order";
export default {
  props: ["isBei", "remarkid", "isgaiBei", "dingId"],
  data() {
    return {
      title: "",
      titleMax: 255,
      beis: this.isBei,
      id: this.remarkid,
      dingbeis: this.isgaiBei,
      radio:'1',
    };
  },
  methods: {
    close() {
      this.beis = false;
      this.title = "";
      this.dingbeis = false;
    },

    async bao() {
      if (this.title == "") {
        this.$message.warning("请输入备注");
        return;
      }

      console.log("备注--------------", this.title, this.id, this.dingId);
      let res = await api.remark(this.id, this.title,this.radio);

      if (res.code == 1) {
        this.$message.warning("成功");
        this.$emit("remarksx", 1);
        this.close();
      } else {
        this.$message.warning(res.message);
        return;
      }
    },
  },
  watch: {
    title() {
      if (this.title.length > this.titleMax.length) {
        this.title = String(this.title).slice(0, this.titleMax);
      }
    },
    isBei(newval) {
      this.beis = newval;
    },
    remarkid(newval) {
      this.id = newval;
    },
    dingId(newval) {
      this.id = newval;
    },
    isgaiBei(newval) {
      this.dingbeis = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.beiBox {
 float: left;
 width: 1920px;
 height: 940px;
 position: relative;
 background: rgba(0, 0, 0, 0.4);
 border-radius: 0px;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 70;
}
.main {
  width: 520px;
  height: 310px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  box-sizing: border-box;
}
.beiNav {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  padding: 0 10px;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
}
h1 {
  float: left;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 500;
  color: #2a3346;
}
.cha {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 17px;
}
label {
  font-size: 16px;
}

.beiMain {
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
}
.wenben {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  box-sizing: border-box;
}
.wenben textarea {
  min-height: 80px;
  min-width: 350px;
  max-width: 500px;
  max-height: 100px;
  padding: 5px 10px;
  font-size: 20px;
}
.wenben span {
  text-align: right;
}
.beiTxt {
  width: 300px;
}
.btn {
  margin-left: 90px;
  margin-top: 20px;
  float: left;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
</style>