import Cookies from "js-cookie";

var websock = null;

var global_callback = null;
var serverPort = "80"; // webSocket连接端口
var wsuri = "wss://dispatch.nituowola.com/websocket/";
var nowuid = null;

function createWebSocket(callback) {
  // console.log(websock);
  // console.log(nowuid);
  //console.log(websock.readyState);
  let uid = Cookies.get("ntwl-uid");
  if(nowuid == uid)
  {
    if (websock == null) {
      initWebSocket(callback);
    } else{
      //websock.send(agentData);
      //console.log('当前uid:',nowuid);npm
    }
  }else{
    if(nowuid == null)
    {
      if (websock == null) {
        initWebSocket(callback);
      } 
    }else{
      closeSockS();
      if (websock == null) {
        //console.log(111);
        initWebSocket(callback);
      }
    }
  }

}

function initWebSocket(callback) {
  global_callback = callback;
  // 初始化websocket
  websock = new WebSocket(wsuri);
  websock.onmessage = function (e) {
      websocketonmessage(e);
  };
  websock.onclose = function (e) {
      websocketclose(e);
  };
  websock.onopen = function () {
      websocketOpen();
  };

  // 连接发生错误的回调方法
  websock.onerror = function () {
    //console.log("WebSocket连接发生错误");
     //createWebSocket();啊，发现这样写会创建多个连接，加延时也不行
  };
}

// 实际调用的方法
function sendSock(agentData ) {

  if(websock != null)
  {
      if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websocketsend(agentData);
      } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
          sendSock(agentData);
        }, 1000);
      } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
          sendSock(agentData);
        }, 1000);
      }
  }
  

}

function closeSockS() {

  if(websock != null)
  { 
      websock.close();
  }
}

// 数据接收
function websocketonmessage(msg) {
  global_callback(msg.data);
}

// 数据发送
function websocketsend(agentData) {
  //console.log("发送数据：" + agentData);
  websock.send(agentData);
}

// 关闭
function websocketclose(e) {
  global_callback('connection:closed');
  websock = null;
}

function websocketOpen(e) {
  //console.log("连接打开");
  let uid = Cookies.get("ntwl-uid");
  let authkey = Cookies.get("ntwl-authkey");
  nowuid = uid;
  websocketsend("login " + uid + " " + authkey);
}

export { sendSock, createWebSocket, closeSockS };
