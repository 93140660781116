<template>
  <div>
    <div class="box" v-show="cars">
      <div id="main">
        <i class="tiao"></i>
        <h1>确定要取消该订单?</h1>
        <div class="mold">
          <label for="">关闭类型</label>
          <el-radio v-model="radio1" label="1" size="large">取消无费</el-radio>
          <el-radio v-model="radio1" label="2" size="large" v-if="canEmpty"
            >取消有费</el-radio>
          <el-radio v-model="radio1" label="3" size="large" v-if="canEmpty">救援失败</el-radio>
        </div>
        <div class="mold1">
            <label for="">车主电话</label>
            {{mobile}}  <button
                    @click="call(mobile)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button>
        </div>
        <div class="mold">
          <label for="">{{ this.name }}原因</label>
          <el-select v-model="reason" placeholder="请选择原因" @change="ddd">
            <el-option
              v-for="item in reasonlist"
              :key="item.content"
              :label="item.content"
              :value="item.content"
            >
            </el-option>
          </el-select>
        </div>
        <div class="beiMain" v-if="showother">
            <label for="">其他原因</label>
            <el-input
                  type="textarea"
                  class="beiTxt"
                  v-model="otherreason"
                  placeholder="请输入具体的原因"
                ></el-input>
        </div>
        <div class="btn">
          <!-- <button class="set" @click="goclose()">确定</button> -->
          <el-button class="set" :plain="true" @click="goclose()"
            >确定</el-button
          >
          <button @click="cel" class="cancel">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import * as api from "../api/orderDetail";
import { ElMessage, ElMessageBox } from "element-plus";
import phonebar from "jssip-emicnet/dist/phonebar";
import * as ola from "../api/olawebsock.js";

export default {
  props: ["iscar", "rescueIds"],
  data() {
    return {
      radio1:'',
      cars: this.iscar,
      id: this.rescueIds,
      canEmpty: false,
      reason: "",
      otherreason:'',
      showother:false,
      name: "关闭",
      reasonlist: [],
      mobile:'',
      emicreg:'',
    };
  },
  created() {},
  methods: {
    cel() {
      this.cars = false;
    },
    ddd(){
        console.log(this.reason);

        if(this.reason == '其他原因'){
          this.showother = true;
        }else{
          this.showother = false;
        }
    },
    async goclose(id) {
      let post_type = this.radio1;
      let post_reason = this.reason;
      let post_otherreason = this.otherreason;
      let that = this;
      // 判断原因是否为空，为空提醒填写，非空再弹窗
      if (post_reason == "" || post_reason == undefined) {
        // alert('请选择原因');
        this.$message.warning("请选择原因");
        return false;
      }else if(post_reason == "其他原因" && post_otherreason == ''){
        this.$message.warning("请补充其他具体原因");
        return false;
      } else {
        if(post_reason == '其他原因')
        {
          post_reason = post_reason + '：' + post_otherreason;
        }
        ElMessageBox.confirm("是否确定取消", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            if (post_type == 1 || post_type == 3) {
              let res = await api.orderClose(this.id, post_reason,post_type);
              if (res.code == 1) {
                // alert('成功');
                that.$message.success("成功");
                that.cel();
              } else {
                // alert(res.message)
                that.$message.warning(rs.message);
              }
            } else {
              let res = await api.orderEmpty(this.id, post_reason);
              if (res.code == 1) {
                // alert('成功');
                that.$message.success("成功");
                that.cel();
              } else {
                // alert(res.message)
                that.$message.warning(res.message);
              }
            }
          })
          .catch(() => {});
      }
    },
    call(tel) {
      tel = tel.toString();
      let str = this.id.toString();

      
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    iscar(newval) {
      //console.log('---',newval);
      console.log(newval);
      console.log(this.emicreg);
      this.cars = newval;
    },
    async rescueIds(newval) {
      this.id = newval;
      let res = await api.OrderCanEmpty(newval);
      console.log(res);
      if (res.code == 1) {
        this.canEmpty = res.data.canEmpty;
        this.mobile = res.data.mobile;
      } else {
        // alert(res.message);
        this.mobile = res.data.mobile;
        this.$message.message(res.message);
        this.cars = false;
      }
    },
    async radio1(newval) {
      this.reason = '';
      this.showother = false;
      this.otherreason = '';
      let res = await api.getCloseReason(newval);
      this.reasonlist = res.data;
    },
    getEmic(newval) {
      this.emicreg = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
 float: left;
 width: 1920px;
 height: 940px;
 position: relative;
 background: rgba(0, 0, 0, 0.4);
 border-radius: 0px;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 70;
}
#main {
  width: 520px;
  height: auto;
  opacity: 1;
  //   background-color: pink;
  background: rgba(255, 255, 255, 1);
  margin: 340px auto;
  position: absolute;
  top: 0;
  left: 700px;
}
h1 {
  width: 490px;
  //height: 60px;
  float: left;
  margin-top: -21px;
  margin-left: 30px;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px 2px 0px 0px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  //line-height: 60px;
  color: #2a3346;
  opacity: 1;
}
.mold /deep/ .el-select .el-input {
  width: 300px;
}
.beiMain /deep/ .el-textarea {
  width: 300px;
  margin-top: -7px;
}
.tiao {
  float: left;
  width: 3px;
  height: 15px;
  margin-top: 23px;
  margin-left: 19px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.mold {
  //width: 340px;
  // height: 19px;
  margin-bottom: 15px;
  margin-left: 30px;
  //   background-color: skyblue;
}
.mold1 {
  margin-bottom: 15px;
  margin-left: 30px;
}
.beiMain {
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
}

label {
  //width: 90px;
  height: 19px;
  font-size: 14px;
  margin-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 30px;
  margin-top: 44px;
  margin-bottom:20px;
  // position: absolute;
  // bottom:50px;
  // left:170px
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
}
</style>
