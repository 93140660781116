import http from "../util/http.js";
import http1 from "../util/http1.js";
import http2 from "../util/http2.js";
import axios from "axios";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";

// 订单列表
// /order/list
export const orderList = (data) => {
  let {
    page,
    perpage,
    createTimeBegin,
    createTimeEnd,
    srcCid,
    rescueStatus,
    chargeType,
    finishTimeBegin,
    finishTimeEnd,
    reviewStatus,
    returnStatus,
    complainStatus,
    rescueType,
    keyword,
    tech,
    saleman,
    kefu,
    diaodu,
    fromtype,
    rescueStyle,
    keytype,
    apTimeBegin,
    apTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    companyType,
  } = data;
  return http
    .get("/order/list-new", {
      params: {
        page,
        perpage,
        createTimeBegin,
        createTimeEnd,
        srcCid,
        rescueStatus,
        chargeType,
        finishTimeBegin,
        finishTimeEnd,
        reviewStatus,
        returnStatus,
        complainStatus,
        rescueType,
        keyword,
        tech,
        saleman,
        kefu,
        diaodu,
        fromtype,
        rescueStyle,
        keytype,
        apTimeBegin,
        apTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        companyType,
      },
    })
    .then((res) => res.data);
};

// 目的地列表
export const workOrder = (data) => {
  let {
    type,
    page,
    perpage,
    keytype,
    keyword,
    rescueStyle,
    createStart,
    createEnd,
    yuyueStart,
    yuyueEnd,
    city,
  } = data;
  return http1
    .get("/order/plat-list-new", {
      params: {
        type,
        page,
        perpage,
        keytype,
        keyword,
        rescueStyle,
        createStart,
        createEnd,
        yuyueStart,
        yuyueEnd,
        city,
      },
    })
    .then((res) => res.data);
};

export const orderListsx = (data) => {
  let {
    page,
    perpage,
    createTimeBegin,
    createTimeEnd,
    srcCid,
    rescueStatus,
    chargeType,
    finishTimeBegin,
    finishTimeEnd,
    reviewStatus,
    returnStatus,
    complainStatus,
    rescueType,
    keyword,
    tech,
    saleman,
    kefu,
    diaodu,
    rescueStyle,
    keytype,
    apTimeBegin,
    apTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    companyType,
  } = data;
  return http
    .get("/order/list-sx-new", {
      params: {
        page,
        perpage,
        createTimeBegin,
        createTimeEnd,
        srcCid,
        rescueStatus,
        chargeType,
        finishTimeBegin,
        finishTimeEnd,
        reviewStatus,
        returnStatus,
        complainStatus,
        rescueType,
        keyword,
        tech,
        saleman,
        kefu,
        diaodu,
        rescueStyle,
        keytype,
        apTimeBegin,
        apTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        companyType,
      },
    })
    .then((res) => res.data);
};

// export function export() {
//   // (* 注意 使用`get/post` 的请求, 参数写法的不同 *)
//   return axios.post('/account/team/export', {}, { responseType: 'blob' // 1. `post` 请求, 也可以使用`arraybuffer` 类型
//   // return axios.get('/account/team/export',{ responseType: 'blob', // 2. `get` 请求
//   }).then(res => {
//       console.log(res) // 打印结果 (见下面)
//       const { headers, data } = res
//       // 创建`Blob` , 用于保存 (输入源)
//       let blob = new Blob([data], {
//           type: headers["content-type"] // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
//           // type: 'application/vnd.ms-excel' // 直接手写指定
//       })
//       // 保存`saveAs`, 使用开源组件`file-saver`, (* 注意headers 中`key 的大小写` *)
//       saveAs(blob, decodeURI(headers['content-disposition'].match(/filename="(.*)"/)[1])) // saveAs(输入源, 输出文件名)
//   })

export const getOrderListOp = () => {
  return http.get("/order/get-list-op", {}).then((res) => res.data);
};

export const getFinancelist = (data) => {
  let {
    page,
    perpage,
    srccid,
    chargeType,
    createTimeBegin,
    createTimeEnd,
    finishTimeBegin,
    finishTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    rescueType,
    keyword,
    tech,
    kefu,
    saleman,
    keytype,
  } = data;
  return http
    .get("/order/get-finance-list", {
      params: {
        page,
        perpage,
        srccid,
        chargeType,
        createTimeBegin,
        createTimeEnd,
        finishTimeBegin,
        finishTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        rescueType,
        keyword,
        tech,
        kefu,
        saleman,
        keytype,
      },
    })
    .then((res) => res.data);
};

export const getFinancelistNew = (data) => {
  let {
    page,
    perpage,
    srccid,
    chargeType,
    createTimeBegin,
    createTimeEnd,
    finishTimeBegin,
    finishTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    rescueType,
    keyword,
    tech,
    kefu,
    saleman,
    keytype,
    hesuan,
    settle,
  } = data;
  return http
    .get("/order/finance-new", {
      params: {
        page,
        perpage,
        srccid,
        chargeType,
        createTimeBegin,
        createTimeEnd,
        finishTimeBegin,
        finishTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        rescueType,
        keyword,
        tech,
        kefu,
        saleman,
        keytype,
        hesuan,
        settle
      },
    })
    .then((res) => res.data);
};

export const remark = (id, remark, is_self) => {
  return http
    .post("/order/remark", {
      id: id,
      remark: remark,
      is_self: is_self,
    })
    .then((res) => res.data);
};

export const orderListExport = (data) => {
  let {
    createTimeBegin,
    createTimeEnd,
    srcCid,
    rescueStatus,
    chargeType,
    finishTimeBegin,
    finishTimeEnd,
    reviewStatus,
    returnStatus,
    complainStatus,
    rescueType,
    keyword,
    tech,
    saleman,
    kefu,
    diaodu,
    fromtype,
    rescueStyle,
    keytype,
    apTimeBegin,
    apTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    companyType,
  } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/order/list-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        createTimeBegin,
        createTimeEnd,
        srcCid,
        rescueStatus,
        chargeType,
        finishTimeBegin,
        finishTimeEnd,
        reviewStatus,
        returnStatus,
        complainStatus,
        rescueType,
        keyword,
        tech,
        saleman,
        kefu,
        diaodu,
        fromtype,
        rescueStyle,
        keytype,
        apTimeBegin,
        apTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        companyType,
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });

      saveAs(blob, decodeURI("订单.xlsx")); // saveAs(输入源, 输出文件名)
      return res;
    });
};

export const SpListExport = (data) => {
  let { keyword, saleman, companytype,status } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/service-provider/list-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        keyword,
        saleman,
        companytype,
        status,
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });

      saveAs(blob, decodeURI("合作商列表.xlsx")); // saveAs(输入源, 输出文件名)
      return res;
    });
};

export const TechListExport = (data) => {
  let { uid, zhongduan, tai,status } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/tech/list-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        uid,
        zhongduan,
        tai,
        status
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });

      saveAs(blob, decodeURI("技师列表.xlsx")); // saveAs(输入源, 输出文件名)
      return res;
    });
};

export const commissionlistExport = (data) => {
  let {
    createTimeBegin,
    createTimeEnd,
    finishTimeBegin,
    finishTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    keyword,
    carnumber,
  } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/order/ticheng-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        createTimeBegin,
        createTimeEnd,
        finishTimeBegin,
        finishTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        keyword,
        carnumber,
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });

      saveAs(blob, decodeURI("提成.xlsx")); // saveAs(输入源, 输出文件名)

      return res;
    });
};

export const getFinancelistExport = (data) => {
  let {
    srccid,
    chargeType,
    createTimeBegin,
    createTimeEnd,
    finishTimeBegin,
    finishTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    rescueType,
    keyword,
    tech,
    kefu,
    saleman,
    keytype,
  } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/order/get-finance-list-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        srccid,
        chargeType,
        createTimeBegin,
        createTimeEnd,
        finishTimeBegin,
        finishTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        rescueType,
        keyword,
        tech,
        kefu,
        saleman,
        keytype,
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });
      saveAs(blob, decodeURI("财务.xlsx")); // saveAs(输入源, 输出文件名)
      return res;
    });
};


export const getFinancelistExportNew = (data) => {
  let {
    srccid,
    chargeType,
    createTimeBegin,
    createTimeEnd,
    finishTimeBegin,
    finishTimeEnd,
    rescueTimeBegin,
    rescueTimeEnd,
    rescueType,
    keyword,
    tech,
    kefu,
    saleman,
    keytype,
    hesuan,
    settle
  } = data;
  return axios
    .get("https://dispatch-test.nituowola.com/order/finance-new-export", {
      headers: {
        token: Cookies.get("ntwl-token"),
      },
      responseType: "blob",
      params: {
        srccid,
        chargeType,
        createTimeBegin,
        createTimeEnd,
        finishTimeBegin,
        finishTimeEnd,
        rescueTimeBegin,
        rescueTimeEnd,
        rescueType,
        keyword,
        tech,
        kefu,
        saleman,
        keytype,
        hesuan,
        settle
      },
    })
    .then((res) => {
      //console.log(res) // 打印结果 (见下面)
      const { headers, data } = res;
      // 创建`Blob` , 用于保存 (输入源)
      let blob = new Blob([data], {
        type: headers["content-type"], // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      });
      saveAs(blob, decodeURI("财务.xlsx")); // saveAs(输入源, 输出文件名)
      return res;
    });
};

// 获取评价信息
export const getEvaluate = (rescue_id) => {
  return http2
    .post("/admin/api/get-order-rating", {
      rescue_id,
    })
    .then((res) => res.data);
};

// 保存评价
export const saveEvaluate = (rating_type, rescue_id, other) => {
  return http2
    .post("/admin/api/save-order-rating", {
      rating_type,
      rescue_id,
      other,
    })
    .then((res) => res.data);
};

export const saveEvaluateAgain = (rating_type, rescue_id, other) => {
  return http2
    .post("/admin/api/save-order-rating-again", {
      rating_type,
      rescue_id,
      other,
    })
    .then((res) => res.data);
};

export const settle = (id) => {
  return http
    .post("/order/settle-order", {
      id: id,
    })
    .then((res) => res.data);
};

export const settleNew = (ids,money,invoice,remark) => {
  return http
    .post("/order/do-settle", {
      ids: ids,
      money:money,
      invoice:invoice,
      remark:remark
    })
    .then((res) => res.data);
};

export const settleNewAll = (ids,invoice,remark) => {
  return http
    .post("/order/do-settle-all", {
      ids: ids,
      invoice:invoice,
      remark:remark
    })
    .then((res) => res.data);
};

export const getShareList = () => {
 
  return http
    .get("/order/get-share-list", {
      params: {
      },
    })
    .then((res) => res.data);
};


export const qiangOrder = (id) => {
  return http
    .post("/order/take-share-order", {
      id: id,
    })
    .then((res) => res.data);
};



export const shareOrder = (id,chargeType,guidePrice,guideKm,kmPrice) => {
  return http
    .post("/order/do-share-order", {
      rescueId: id,
      chargeType:chargeType,
      guidePrice:guidePrice,
      guideKm:guideKm,
      kmPrice:kmPrice,
    })
    .then((res) => res.data);
};


export const hesuanOrder = (id) => {
  return http
    .post("/order/do-hesuan", {
      id: id,
    })
    .then((res) => res.data);
};

export const hesuanOrderall = (id) => {
  return http
    .post("/order/do-hesuan-all", {
      ids: id,
    })
    .then((res) => res.data);
};


export const h5shareorder = (id,mobile) => {
  return http
    .post("/order/do-h5-share", {
      id: id,
      mobile:mobile
    })
    .then((res) => res.data);
};


export const h5message = (url,mobile) => {
  return http
    .post("/order/do-h5-message", {
      url: url,
      mobile:mobile
    })
    .then((res) => res.data);
};


export const carframe = (id, vin) => {
  return http
    .post("/order/edit-vin", {
      id: id,
      vin: vin,
    })
    .then((res) => res.data);
};



export const dosettleall = (data) => {
  let {
      srccid,
      createTimeBegin,
      createTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      rescueTimeBegin,
      rescueTimeEnd,
      saleman
  } = data;
  return http
    .get("/order/do-all-settle", {
      params: {
          srccid,
          createTimeBegin,
          createTimeEnd,
          finishTimeBegin,
          finishTimeEnd,
          rescueTimeBegin,
          rescueTimeEnd,
          saleman
      },
    })
    .then((res) => res.data);
};


export const dohesuanall = (data) => {
  let {
      srccid,
      createTimeBegin,
      createTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      rescueTimeBegin,
      rescueTimeEnd,
      saleman
  } = data;
  return http
    .get("/order/do-all-hesuan", {
      params: {
          srccid,
          createTimeBegin,
          createTimeEnd,
          finishTimeBegin,
          finishTimeEnd,
          rescueTimeBegin,
          rescueTimeEnd,
          saleman
      },
    })
    .then((res) => res.data);
};
