<template>
  <div class="searchBox" v-show="isWeiedit">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="searchTit">位置选择</p>

      <div class="xuanze">
        <label class="xuanzeTit" for="">选择位置</label>
        <input
          class="xuanzeInput"
          type="text"
          id="addr"
          autocomplete="off"
          v-model="addredit"
          placeholder="请在地图上选择位置"
        />
        <input type="hidden" id="lnglat" v-model="vmfoundlnglatedit" />
        <button class="quer1" @click="search1">搜索</button>
        <button class="quer" @click="queding1">确认</button>
      </div>

      <div class="searchmapBox">
        <div id="gaodemap"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import * as api from "../api/index";
export default {
  props: ["isWeiedit", "isaddredit", "foundaddredit", "foundlnglatedit"],
  data() {
    return {
      addredit: this.foundaddredit,
      vmfoundlnglatedit: "",
      lnglatedit: this.foundlnglatedit,
      mapedit: "",
    };
  },
  created() {
    window.getaddress1 = this.getaddress1;
  },

  methods: {
    close() {
      this.$emit("hide");
    },
    createmap() {
      if (this.lnglatedit != "" && this.lnglatedit != undefined) {
        var a = this.lnglatedit.split(",");
        var map2 = new AMap.Map("gaodemap", {
          center: [a[0], a[1]],
          resizeEnable: true,
          zoom: 13,
        });
        console.log("进来了", this.addredit);
        console.log("进来了", this.lnglatedit);
        document.getElementById("addr").value = this.addredit;
        document.getElementById("lnglat").value = this.lnglatedit;
        CreateMarker([a[0], a[1]]);
      } else {
        var map2 = new AMap.Map("gaodemap", {
          center: [121.621989, 29.853658],
          resizeEnable: true,
          zoom: 10,
        });
        document.getElementById("addr").value = "";
        document.getElementById("lnglat").value = "";
      }

      map2.on("click", OnMapClick);

      map2.setFitView();

      function OnMapClick(e) {
        map2.clearMap();

        setLocation(e.lnglat, true);

        getaddress1(e.lnglat.getLng(), e.lnglat.getLat());
      }

      function setLocation(argPoint, bCenter) {
        if (argPoint) {
          // 将标注添加到地图中
          CreateMarker(argPoint);

          // 移动到视野中心
          if (bCenter) map2.setCenter(argPoint);
        }
      }
      function CreateMarker(point) {
        var opt = {
          map: map2,
          position: point,
        };
        return new AMap.Marker(opt);
      }
    },
    async search1() {
      let res = await api.workMap(this.addredit);
      this.lnglatedit = res.data[0].location;
      this.createmap();
    },
    async getaddress1(lng, lat) {
      let res = await api.getaddress(lng + "," + lat);
      this.addredit = res.data.addr;
      this.lnglatedit = lng + "," + lat;
      // console.log("选中地址",res);
    },
    queding1() {
      let addr = this.addredit;
      let lnglat = this.lnglatedit;
      if (this.isaddredit == 1) {
        this.$emit("addr1", addr);
        this.$emit("addr1lnglat", lnglat);
        this.close();
      } else {
        this.$emit("addr2", addr);
        this.$emit("addr2lnglat", lnglat);
        this.close();
      }
    },
  },

  watch: {
    // isWeiedit(newval) {
    //   console.log("----", newval);
    //   this.weizhisedit = newval;
    //   this.createmap();
    // },
    foundaddredit(newval) {
      console.log("进入地图1", newval);
      this.addredit = newval;
    },
    foundlnglatedit(newval) {
      console.log("进入地图2", newval);
      this.lnglatedit = newval;
      this.createmap();
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 640px;
  background-color: skyblue;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 71;
}
.searchMain {
  width: 640px;
  height: 920px;
  background-color: #fff;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  padding-left: 30px;
  padding-top: 40px;
  font-weight: bold;
  box-sizing: border-box;
}
.xuanze {
  margin-top: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.xuanzeTit {
  font-size: 14px;
  color: #2a3346;
  margin-right: 8px;
}
.xuanzeInput {
  width: 350px;
  height: 32px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  padding-left: 12px;
}
.quer {
  height: 32px;
  padding: 0 16px;
  border: 0;
  background-color: #2c68ff;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.quer1 {
  height: 32px;
  padding: 0 16px;
  border: 0;
  background-color: #4fff2c;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.searchmapBox {
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  margin-top: 10px;
}
#gaodemap {
  width: 600px;
  height: 100%;
  border: 1px solid #dcdfe6;
}
</style>