<template>
  <div class="beiBox">
    <div class="main">
      <div class="beiNav">
        <h1>订单#{{xiangid}}的H5分享</h1>
        <img
          class="cha"
          @click="close"
          src="../assets/img/index/cha.png"
          alt=""
        />
      </div>

      <div class="beiMain">
        <div class="entryinput">
          <el-input
            v-model="value"
            placeholder="请输入手机号"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
              <el-button @click="fabtn">确认</el-button>
        </div>
        <div class="copylink" v-show="!jinbtn">
          <el-input disabled v-model="url"></el-input>
          <el-button style="background:#53a8ff;color:white" v-show="!jinbtn" @click="copy()">复制链接</el-button>
          <el-button type="success" @click="send()" v-show="!jinbtn" :disbled="issend">发送短信</el-button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/order";
import { ElMessageBox } from "element-plus";
export default {
   props: [
    "xiangid"
  ],
  data() {
    return {
      value: "",
      url:'',
      jinbtn: true,
      issend:false,
    };
  },
  methods: {
    copy() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.url; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },

    close() {
      this.value = '';
      this.url = '';
      this.jinbtn = true;
      this.issend = false;
      this.$emit("guanpientyr");
    },
    async fabtn() {
      if(/^[0-9][0-9- ]{6,10}[0-9]$/.test(this.value) !== true || this.value == '') {
        this.$message.warning("请输入正确手机号");
        return;
      }else{
          ElMessageBox.confirm("确认是否生成H5链接", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
              let res = await api.h5shareorder(this.xiangid,this.value);
              if(res.code == 1)
              {
                this.jinbtn = false;
                this.url = res.data;
              }else{
                this.$message.warning(res.message);
              }

          })
          .catch(() => {
            this.$message.info("取消");
          });

      }
    },
    async send()
    {
        if(this.issend == true)
        {
            this.$message.warning('短信已发送，请勿重复点击');
            return;
        }
        ElMessageBox.confirm("确认是否发送短信", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
              let res = await api.h5message(this.url,this.value);
              if(res.code == 1)
              {
                this.issend = true;
                this.$message.success(res.message);
              }else{
                this.$message.warning(res.message);
              }

          })
          .catch(() => {
            this.$message.info("取消");
          });

    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.beiBox {
  float: left;
  width: 1920px;
  height: 940px;
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 70;
}
.main {
  width: 520px;
  //height: 200px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  box-sizing: border-box;
}
.beiNav {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  padding: 0 10px;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
}
h1 {
  float: left;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 500;
  color: #2a3346;
}
.cha {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 17px;
}

.beiMain {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}
.entryinput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom:20px;
}
.entryinput /deep/ .el-input__inner {
  width: 380px;
}
.entryinput /deep/ .el-button {
  width: 100px;
}
.copylink {
  display: flex;
  justify-content: flex-end;
  margin-bottom:20px;
}
</style>