<template>
  <div class="carlei">
    <table border="0" cellspacing="0">
      <thead>
        <th class="carStyle">车辆类型</th>
        <th class="Commission">提成比</th>
        <th class="caozuo">操作</th>
      </thead>
      <tbody>
        <tr
          :class="
            (index % 2 == 0 && !changecolor) || (index % 2 !== 0 && changecolor)
              ? 'bg_color'
              : 'bg_color2'
          "
          v-for="(item, index) in carList"
          :key="index"
        >
          <td class="td-carStyle">{{ item.type_name }}</td>
          <td class="td-Commission">{{ item.percentage}}%</td>
          <td class="td-caozuo" @click="xiu(item.type_name,item.percentage)">
             修改
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <xiucar :isxiu="gai" :xiucartype="xiucartypes" :xiuticheng="xiutichengs" @xiuchange="sx"></xiucar>
</template>

<script>
import Xiucar from "./xiucar.vue";
import * as api from '../api/car.js';
export default {
  props: ["jiaZhi"],
  components: { Xiucar },
  data() {
    return {
      gai: 0,
      xiucartypes:'',
      xiutichengs:'',
      carList:[],
    };
  },
  created(){
    this.getlist();
  },
  methods: {
    xiu(type,ticheng) {
      this.xiucartypes = type;
      this.xiutichengs = ticheng;
      this.gai++;
    },
    sx()
    {
      this.getlist();
    },
    async getlist()
    {
      let res = await api.getCarStyleList();
      console.log(res);
      if(res.code == 1)
      {
        this.carList = res.data.list
      }
    }
  },
  watch:{
    jiaZhi(newValue) {
        this.getlist();
    }
  }
};
</script>

<style lang="less" scoped>
table {
  width: 1610px;
  margin-left: 30px;
}
thead {
  text-align: left;
  width: 1610px;
  height: 50px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
th,
td {
  padding-left: 12px;
  font-size: 14px;
}
.carlei {
  text-align: left;
}
.carStyle,
.Commission {
  width: 733px;
}
.caozuo {
  width: 144px;
}
td {
  height: 70px;
  box-sizing: border-box;
}
.td-caozuo {
  color: #2c68ff;
  cursor: pointer;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}
</style>